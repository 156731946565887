import cn from "classnames";
import { LinkField } from "@prismicio/types";
import { ReactNode } from "react";
import { HyamPrismicLink } from "hyam-prismic/components";
import { styles as moduleStyles, HyamPrismicButtonStyles } from "./styles";

import { withConfigure } from "hyam-core/utils/withConfigure";

export type HyamPrismicButtonProps = {
  children: ReactNode;
  field?: LinkField;
  href?: string | null;
  styles?: Partial<HyamPrismicButtonStyles>;
  stylesReplace?: Partial<HyamPrismicButtonStyles>;
  variant?:
    | "Contained Button"
    | "Contained Button White"
    | "Outline Button"
    | "Text Button"
    | "Contained Secondary Button"
    | "Contained Secondary Button Black"
    | null;
  onClick?: () => void;
  buttonType?: "button" | "submit";
  leftIcon?: JSX.Element | null;
  rightIcon?: JSX.Element | null;
  rightIconExternalLink?: JSX.Element;
  disabled?: boolean;
};

const _HyamPrismicButton = (props: HyamPrismicButtonProps) => {
  const {
    children,
    field,
    href,
    onClick,
    buttonType = "button",
    leftIcon,
    disabled = false,
  } = props;
  const variant = props.variant || "Contained Button";
  const styles = props.styles as HyamPrismicButtonStyles;
  const styles_container = cn(
    {
      "Contained Button": cn(styles.container_contained),
      "Contained Button White": cn(
        styles.container_contained,
        styles.container_contained_white,
      ),
      "Outline Button": styles.container_outline,
      "Text Button": styles.container_text,
      "Contained Secondary Button": styles.container_contained_secondary,
      "Contained Secondary Button Black": styles.container_contained_secondary_black,
    }[variant],
    styles.container,
  );

  if (!children) return null;

  // is external link and external link icon is defined
  const isExternalLink = field?.link_type === "Web" && props.rightIconExternalLink;

  const rightIcon = isExternalLink ? props.rightIconExternalLink : props.rightIcon;

  return (
    <HyamPrismicLink
      onClick={onClick}
      href={href as string}
      field={field}
      className={styles.prismic_link_wrapper}
    >
      <button disabled={disabled} type={buttonType} className={styles_container}>
        {leftIcon && <span className={styles.left_icon_container}>{leftIcon}</span>}
        {children}
        {rightIcon && <span className={styles.right_icon_container}>{rightIcon}</span>}
      </button>
    </HyamPrismicLink>
  );
};

export const HyamPrismicButton = withConfigure<
  HyamPrismicButtonProps,
  HyamPrismicButtonStyles
>({
  componentName: "HyamPrismicButton",
  Component: _HyamPrismicButton,
  moduleStyles,
});
