import { extendTailwindMerge } from "tailwind-merge";
import classNames from "classnames";

export const twMerge = extendTailwindMerge({
  theme: {
    spacing: ["hyam-navbar-h-sm", "hyam-navbar-h-xl", "hyam-navbar-h-xl"],
    colors: [{ "hyam-": [() => true] }],
  },
  classGroups: {
    hyam: [{ "hyam-text": [() => true] }],
  },
});

type ClassNames = typeof classNames;

export const classNamesWithMerge: ClassNames = (...args) => {
  return twMerge(classNames(...args));
};
