import { useContext, useCallback } from "react";
import Cookie from "js-cookie";
import { useRouter } from "next/router";
import { HyamPrismicGlobalData } from "hyam-prismic/components";
import { linkResolverAltLang } from "hyam-prismic/utils/linkResolver";

/**
 * Updates Usercentrics language only if Usercentrics is defined for the app
 */
const switchLangUsercentrics = (lang: string) => {
  const windowAny = window as any;

  if (!windowAny?.UC_UI?.updateLanguage) return;

  const [shortLang] = lang.split("-");

  windowAny.UC_UI.updateLanguage(shortLang);
};

export const useSwitchLang = () => {
  const router = useRouter();
  const { page } = useContext(HyamPrismicGlobalData);

  const switchLang = useCallback(
    (lang: string) => {
      const locale = router.locale;

      const isCurrentLang = lang === locale;
      if (isCurrentLang || !page) return;
      const url = linkResolverAltLang(page, lang as string);

      Cookie.set("NEXT_LOCALE", lang || "");
      router.push(url, url, { locale: lang });

      switchLangUsercentrics(lang);
    },
    [router.asPath, page?.uid, page?.url],
  );

  return { switchLang };
};
