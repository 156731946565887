import cn from "classnames";
import {
  firstLoadContainer,
  pageTransitionContainer,
} from "hyam-core/animation/defaults";

export const styles = {
  container: cn(
    "hyam-layout-container bg-white",
    "justify-between",
    "min-h-screen",
    "overflow-x-hidden",
  ),

  children_container:
    "grow hyam-layout min-h-screen",
};

export type HyamLayoutStyles = typeof styles;

export const animationProps = {
  firstLoad: {
    container: firstLoadContainer,
    children_container: {},
  },
  pageTransition: {
    container: {},
    children_container: pageTransitionContainer,
  },
};

export type HyamLayoutAnimationProps =
  typeof animationProps;
