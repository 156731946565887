import { PrismicRichText } from "@prismicio/react";
import { RichTextField } from "@prismicio/types";
import { PrismicNextImage } from "@prismicio/next";
import { mergeStyles } from "hyam-core/utils";
import { styles as moduleStyles } from "./styles";
import { twMerge } from "hyam-core/utils/tailwindMerge";
import _ from "lodash";
import { MotionOl, MotionUl, makeMotionComponent } from "hyam-core/components";
import { HyamPrismicLink } from "../HyamPrismicLink";

export type HyamPrismicRichTextProps = {
  field: RichTextField;
  styles?: Partial<typeof moduleStyles>;
  stylesReplace?: Partial<typeof moduleStyles>;
  motionDisabled?: boolean;
};

type VariableTextProps = {
  children: JSX.Element[];
  styles: typeof moduleStyles;
  stylesReplace: typeof moduleStyles;
  type: PrismicRichTextTags;
  motionDisabled: boolean;
};

type PrismicRichTextTags =
  | "paragraph"
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "heading6";

type HTMLTextTags = "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const richTextTagToHtmlTag: Record<PrismicRichTextTags, HTMLTextTags> = {
  paragraph: "p",
  heading1: "h1",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  heading5: "h5",
  heading6: "h6",
};

/**
 * Get custom HTML tag based on custom label "tag-*"
 */
const getCustomHTMLTag = (props: any) => {
  const spans = props?.node?.spans;
  let match: HTMLTextTags | undefined;

  _.find(spans, (span) => {
    const label: string = span?.data?.label || "";
    match = label.match(/tag-(.*)/)?.[1] as HTMLTextTags | undefined;

    return match;
  });

  return match;
};

/**
 * Renders text HTML tags with several flexible options for styling and HTML tag
 * replacement. These flexible options allows us to decouple html tags from
 * styling.
 *
 * For example, using VariableText we can render
 * - h2 html text with h1 styling,
 * - p html text with h5 styling,
 * - etc.
 *
 * This functionality is needed for SEO and accessibility topics.
 */
const VariableText = (props: VariableTextProps) => {
  const { children, styles, stylesReplace, type, motionDisabled } = props;
  const tag = getCustomHTMLTag(props) || richTextTagToHtmlTag[type];

  const Tag = makeMotionComponent(tag);

  const style = stylesReplace.all ? stylesReplace.all : twMerge(styles[type], styles.all);

  return (
    <Tag motionDisabled={motionDisabled} className={style}>
      {children}
    </Tag>
  );
};

export const HyamPrismicRichText = ({
  field,
  styles: propsStyles = {},
  stylesReplace: propsStylesReplace = {},
  motionDisabled = true,
}: HyamPrismicRichTextProps) => {
  const styles = mergeStyles(
    moduleStyles,
    propsStyles,
    propsStylesReplace,
    "HyamPrismicRichText",
  );
  const stylesReplace = propsStylesReplace as typeof moduleStyles;

  const props = { motionDisabled, styles, stylesReplace };

  return (
    <PrismicRichText
      field={field}
      components={{
        paragraph: (element) => <VariableText {...element} {...props} />,
        heading1: (element) => <VariableText {...element} {...props} />,
        heading2: (element) => <VariableText {...element} {...props} />,
        heading3: (element) => <VariableText {...element} {...props} />,
        heading4: (element) => <VariableText {...element} {...props} />,
        heading5: (element) => <VariableText {...element} {...props} />,
        heading6: (element) => <VariableText {...element} {...props} />,
        image: ({ node }) => (
          <PrismicNextImage className={twMerge(styles.image, styles.all)} field={node} />
        ),
        hyperlink: ({ node, children, key }) => {
          return (
            <HyamPrismicLink
              className={twMerge(styles.hyperlink, styles.all)}
              key={key}
              field={node.data}
            >
              {children}
            </HyamPrismicLink>
          );
        },
        oList: ({ children }) => (
          <MotionOl
            motionDisabled={motionDisabled}
            className={twMerge(styles.oList, styles.all)}
          >
            {children}
          </MotionOl>
        ),
        oListItem: ({ children }) => (
          <li className={twMerge(styles.oListItem, styles.all)}>{children}</li>
        ),
        list: ({ children }) => (
          <MotionUl
            motionDisabled={motionDisabled}
            className={twMerge(styles.list, styles.all)}
          >
            {children}
          </MotionUl>
        ),
        listItem: ({ children }) => (
          <li className={twMerge(styles.listItem, styles.all)}>{children}</li>
        ),
      }}
    />
  );
};
