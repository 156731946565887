import cn from "classnames";

export const styles = {
  container: "w-full hyam-layout-container",
  layout_container:
    "hyam-slice-container hyam-layout",

  top_container: "hyam-cols",
  brand_container: cn(
    //
    "brand_container",
    "col-span-2",
    "md:col-span-6",
    "xl:col-span-3",
  ),
  brand_logo: "mb-6",
  brand_description: "text-hyam-gray-500",
  social_icons_container:
    "flex mt-6 mb-12 xl:mb-0",
  social_media_icon: "mr-8 cursor-pointer",
  links_container: cn(
    //
    "hyam-cols",
    "gap-y-8",
    "col-span-2 col-start-1 grid-cols-2",
    "md:col-span-6 md:col-start-1 md:grid-cols-6",
    "xl:col-span-8 xl:col-start-5 xl:grid-cols-8",
    "xl:gap-y-0",
  ),
  link_col_container: cn(
    //
    "col-span-2",
    "md:col-span-3",
    "xl:col-span-2",
  ),

  bottom_container: cn(
    "hyam-cols",
    "gap-y-10",
    "mt-12 xl:mt-10",
  ),

  copyright_information: cn(
    "text-hyam-gray-500",
    "col-span-2 md:col-span-6 xl:col-span-5",
    "order-2 xl:order-1",
  ),

  lang_switch_container: cn(
    "col-start-1 xl:col-start-11",
    "order-1 xl:order-2",
  ),
  lang_switch_label: "",
};

export const constants = {
  social_media_icon_width: 18,
};

export type HyamPrismicDocumentFooterStyles =
  typeof styles;
