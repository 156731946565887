import _ from "lodash";
import { Slice } from "@prismicio/types";
import { components } from "root/slices";

export type RenderSlicesToArrayProps = {
  slices: Slice[];
  // TODO styles for each slice type
  styles?: { all: { container?: string } };
};

/**
 * This is similar to SlizeZone, but it renders all the slices into an array,
 * which can be useful for styling or structuring.
 */
export const renderSlicesToArray = ({
  slices,
  styles = { all: {} },
}: RenderSlicesToArrayProps) => {
  // NOTE: using any type here to simplify typings for all different slice components
  return _.map(slices, (slice: any) => {
    const slice_type = slice.slice_type as keyof typeof components;
    const Component: any = components?.[slice_type];

    if (!Component) {
      console.error("No slice component for:", slice_type);
      return null;
    }

    return <Component key={slice.id} slice={slice} styles={styles.all} />;
  });
};
