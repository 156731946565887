import { useEffect } from "react";
import { useEventListener } from "hyam-core/utils/eventTarget";

const getScrollbarWidth = () => {
  if (typeof window === "undefined") return 0;
  return window.innerWidth - document.documentElement.clientWidth;
};

let __prevScrollbarWidth = 0;

const _setScrollbarWidthCssVar = () => {
  const root: any = document.querySelector(":root");
  if (!root) return;

  const scrollbarWidth = getScrollbarWidth();

  if (scrollbarWidth !== __prevScrollbarWidth) {
    root.style.setProperty("--hyam-scrollbar-width", `${scrollbarWidth}px`);
    __prevScrollbarWidth = scrollbarWidth;
  }
};

const setScrollbarWidthCssVar = () => {
  _setScrollbarWidthCssVar();

  // retry, this is need sometimes, for example, when navbar drawer opens/clses
  setTimeout(_setScrollbarWidthCssVar, 1);
};

export const useLayoutCssVariables = () => {
  useEffect(() => {
    setScrollbarWidthCssVar();

    const resizeObserver = new ResizeObserver(() => {
      setScrollbarWidthCssVar();
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);

  useEventListener(
    {
      name: "scroll_lock_change",
      cb: setScrollbarWidthCssVar,
    },
    [],
  );
};
