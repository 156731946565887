import { useContext, useState } from "react";
import { motion } from "framer-motion";
import _ from "lodash";
import { useRouter } from "next/router";
import { withConfigure } from "hyam-core/utils/withConfigure";
import globalData from "data/globalData.json";
const { all_languages } = globalData;
import {
  styles as moduleStyles,
  animationProps,
  HyamPrismicLangSwitchDropdownStyles,
} from "./styles";
import Globe from "hyam-core/svg/globe-24px.svg";
import ChevronUp from "hyam-core/svg/chevron-up-24px.svg";
import { HyamPrismicGlobalData } from "hyam-prismic/components";
import { useEventListener } from "hyam-core/utils/eventTarget";
import { classNamesWithMerge as cn } from "hyam-core/utils/tailwindMerge";
import { useSwitchLang } from "hyam-prismic/utils/locale";

export type HyamPrismicLangSwitchDropdownProps = {
  styles?: Partial<HyamPrismicLangSwitchDropdownStyles>;
  stylesReplace?: Partial<HyamPrismicLangSwitchDropdownStyles>;
  variation?: "light" | "dark";
};

const _HyamPrismicLangSwitchDropdown = (props: HyamPrismicLangSwitchDropdownProps) => {
  const _styles = props.styles as HyamPrismicLangSwitchDropdownStyles;
  const { variation = "light" } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const router = useRouter();
  const { page } = useContext(HyamPrismicGlobalData);
  const { switchLang } = useSwitchLang();

  const locale = router.locale || "en";
  const currentLang = locale.split("-")[0];
  const langLabel = currentLang.toUpperCase();

  const allLangsMinusCurrentLang: string[] = [];
  _.each(all_languages, (l) => {
    if (l !== currentLang) allLangsMinusCurrentLang.push(l);
  });

  useEventListener(
    {
      name: "nav_bar_is_hidden",
      cb: () => setDropdownOpen(false),
    },
    [],
  );

  const styles = _.mapValues(_styles, (style, styleKey) => {
    // default variation
    if (variation === "light") return style;

    const style_dark = (_styles as any)?.[styleKey + "_dark"];

    // apply variations conditionally
    return cn(style, {
      [style_dark]: variation === "dark",
    });
  });

  return (
    <motion.div
      className={styles.container}
      onClick={() => setDropdownOpen((val) => !val)}
      animate={dropdownOpen ? "open" : "close"}
    >
      <div className={styles.button_container}>
        <Globe className={styles.globe_icon} />
        <div className={styles.button_label}>{langLabel}</div>
        <motion.div className={styles.chevron_icon} {...animationProps.chevron_icon}>
          <ChevronUp />
        </motion.div>
      </div>
      <motion.div
        className={styles.dropdown_container}
        {...animationProps.dropdown_container}
      >
        {_.map(allLangsMinusCurrentLang, (lang, key) => {
          const newLang = lang.split("-")[0];

          return (
            <div key={key} className={styles.lang_option_container}>
              <div
                className={styles.lang_option_label}
                onClick={() => {
                  if (!page || !dropdownOpen) return;
                  switchLang(lang);
                }}
              >
                {newLang.toUpperCase()}
              </div>
            </div>
          );
        })}
      </motion.div>
    </motion.div>
  );
};

export const HyamPrismicLangSwitchDropdown = withConfigure<
  HyamPrismicLangSwitchDropdownProps,
  HyamPrismicLangSwitchDropdownStyles
>({
  componentName: "HyamPrismicLangSwitchDropdown",
  Component: _HyamPrismicLangSwitchDropdown,
  moduleStyles,
});
