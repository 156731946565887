import { useEffect } from "react";

export const useInitialUserEvent = (cb: () => void) => {
  const removeEvents = () => {
    document.removeEventListener("scroll", onUserEvent);
    document.removeEventListener("pointerdown", onUserEvent);
    document.removeEventListener("pointerover", onUserEvent);
  };

  const addEvents = () => {
    document.addEventListener("scroll", onUserEvent);
    document.addEventListener("pointerdown", onUserEvent);
    document.addEventListener("pointerover", onUserEvent);
  };

  const onUserEvent = () => {
    removeEvents();
    cb();
  };

  useEffect(() => {
    addEvents();

    return removeEvents;
  }, []);
};
