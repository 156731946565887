import _ from "lodash";
import { urlJoin, rootUrl } from "hyam-core/utils/url";
import NextHead from "next/head";
import * as prismicH from "@prismicio/helpers";
import { AnyPageDocument } from "hyam-prismic/types";
import { linkResolverAltLang } from "hyam-prismic/utils/linkResolver";
import { HyamPrismicJsonLD } from "hyam-prismic/components";
import { useRouter } from "next/router";

export type HyamPrismicSeoHeadProps = {
  page: AnyPageDocument;
  defaultLanguage: string;
};

const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || "development";

export const HyamPrismicSeoHead = (props: HyamPrismicSeoHeadProps) => {
  const { page, defaultLanguage } = props;

  const { pathname } = useRouter();

  if (!page?.data) {
    console.log("___ SEO no page.data, setting robots to noindex:", pathname);

    return (
      <NextHead>
        <meta name="robots" content="noindex" />
      </NextHead>
    );
  }

  const { data, alternate_languages, url, lang } = page;

  const meta_image = data?.meta_image;
  const meta_title = data?.meta_title || "";
  const meta_description = data?.meta_description || "";
  const [shortLang] = lang.split("-");

  const metaImageSrc =
    prismicH.asImageSrc(meta_image, {
      w: 1200,
    }) || "";

  const canonicalUrl = urlJoin(rootUrl, url);

  const isNoIndex = VERCEL_ENV !== "production" || data?.no_index;

  console.log("\n___ isNoIndex VERCEL_ENV", VERCEL_ENV);
  console.log("___ isNoIndex isNoIndex ", isNoIndex);
  console.log("___ isNoIndex TODO enable when releasing website");

  return (
    <>
      <NextHead>
        <title>{meta_title}</title>
        {/* isNoIndex && <meta name="robots" content="noindex" /> */}
        <meta name="robots" content="noindex" />
        <meta
          name="google-site-verification"
          content={process.env.GOOGLE_SITE_VERIFICATION}
        />
        <meta name="description" content={meta_description} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta property="og:image" content={metaImageSrc} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={meta_description} />
        <meta property="og:locale" content={shortLang} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={meta_title} />
        <meta name="twitter:description" content={meta_description} />
        <meta name="twitter:image" content={metaImageSrc} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <link rel="alternate" hrefLang={shortLang} href={canonicalUrl} />;
        {_.map(alternate_languages, (altLang, key) => {
          const [altShortLang] = altLang.lang.split("-");
          const isDefaultLang = defaultLanguage === altLang.lang;
          const altLangSlug = linkResolverAltLang(page, altLang.lang);
          const altLangHref = urlJoin(
            rootUrl,
            isDefaultLang ? "" : altShortLang,
            altLangSlug,
          );

          return (
            <link key={key} rel="alternate" hrefLang={altShortLang} href={altLangHref} />
          );
        })}
      </NextHead>
      <HyamPrismicJsonLD {...props} />
    </>
  );
};
