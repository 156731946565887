import { useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { useBreakpoint, useBreakpointIndex } from "hyam-core/hooks/useBreakpoint";

export const HyamGridGuide = () => {
  const [show, setShow] = useState(process.env.NEXT_PUBLIC_SHOW_GRID === "enabled");

  const breakpoint = useBreakpoint();
  const breakpointIndex = useBreakpointIndex();
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    // Show on ctrl + g
    function onKeyPress(e: any) {
      let evtobj = window.event ? event : e;
      if (evtobj.keyCode == 71 && evtobj.ctrlKey) {
        setShow((val) => !val);
      }
    }

    document.onkeydown = onKeyPress;
  }, []);

  if (!show) return null;

  return (
    <>
      <div className="HyamGridGuide fixed z-[100] top-0 left-0 flex flex-col items-center pointer-events-none hyam-full-width h-screen">
        <div className="hyam-cols hyam-layout">
          <div className="bg-hyam-primary-600/5 h-screen md:block" />
          <div className="bg-hyam-primary-600/5 h-screen md:block" />
          <div className="bg-hyam-primary-600/5 h-screen md:block hidden" />
          <div className="bg-hyam-primary-600/5 h-screen md:block hidden" />
          <div className="bg-hyam-primary-600/5 h-screen md:block hidden" />
          <div className="bg-hyam-primary-600/5 h-screen md:block hidden" />

          <div className="bg-hyam-primary-600/5 h-screen xl:block md:hidden hidden" />
          <div className="bg-hyam-primary-600/5 h-screen xl:block md:hidden hidden" />
          <div className="bg-hyam-primary-600/5 h-screen xl:block md:hidden hidden" />
          <div className="bg-hyam-primary-600/5 h-screen xl:block md:hidden hidden" />
          <div className="bg-hyam-primary-600/5 h-screen xl:block md:hidden hidden" />
          <div className="bg-hyam-primary-600/5 h-screen xl:block md:hidden hidden" />
        </div>
      </div>
      <div className="fixed z-50 bottom-0 left-0 pointer-events-none bg-hyam-white/50 rounded-lg">
        <pre className="p-4">
          {windowWidth}px {breakpoint} {breakpointIndex}
        </pre>
      </div>
    </>
  );
};
