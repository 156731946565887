import _ from "lodash";
import { AnyPageDocument } from "hyam-prismic/types";
import routes from "root/prismic-routes";

const routesEn: Record<string, string> = {};
const routesDe: Record<string, string> = {};

_.each(routes, (route) => {
  if (route.type === "home_page" || route.type === "page") return;

  // (1) German routes
  if (route.lang !== "en") {
    const path = route.path.match(/(.*)(:.*)/)?.[1];

    if (!path) {
      console.log("___ ERROR linkResolverAltLang cannot find German path for ", route);
      // throw new Error("linkResolverAltLang cannot find German path for " + route.type);
    }

    routesDe[route.type] = path || "";
    return;
  }

  // (2) English routes
  const path = route.path.match(/(.en)(.*)(:.*)/)?.[2];

  if (!path) {
    console.log("___ ERROR linkResolverAltLang cannot find English path for ", route);
    // throw new Error("linkResolverAltLang cannot find English path for " + route.type);
  }

  routesEn[route.type] = path || "";
});

type LinkResolverDoc = AnyPageDocument;

/** Link resolver for alternate languages */
export const linkResolverAltLang = (doc: LinkResolverDoc, lang: string) => {
  // (1) home_page
  if (doc.type === "home_page") return "/";

  const altLang = _.find(doc?.alternate_languages, (alt) => {
    return alt?.lang === lang;
  });

  // (2) page
  if (!altLang?.uid) return "/";
  if (doc.type === "page") return "/" + altLang.uid;

  const subdir = lang === "en" ? routesEn[doc.type] : routesDe[doc.type];

  // (3) page type not found
  if (!subdir) return "/";

  // (4) all other page types
  return subdir + altLang.uid;
};
