import { useEffect, useRef } from "react";
import { dispatchEvent } from "hyam-core/utils/eventTarget";

/**
 * Global window.onscroll hook. Dispatches "on-scroll-window" event on window scroll.
 */
export const useOnScrollWindow = () => {
  const scrollTopPrev = useRef(0);
  //Threshold is used to make sure that the nav is visible
  //only after a certain amount of vertical scroll

  const thresholdUpCounter = useRef(0);
  let thresholdUp;

  useEffect(() => {
    window.onscroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollTopDiff = scrollTopPrev.current - scrollTop;

      const direction = scrollTopDiff < 0 ? "down" : "up";

      //We keep track of the amount of scrolling in the "Up" direction
      if (direction == "up" && scrollTopPrev.current > 0) {
        thresholdUpCounter.current += scrollTopDiff;
      } else {
        thresholdUpCounter.current = 0;
      }

      thresholdUp = thresholdUpCounter.current;
      scrollTopPrev.current = scrollTop;

      dispatchEvent({
        name: "on_scroll_window",
        detail: {
          scrollTop,
          direction,
          thresholdUp,
        },
      });
    };
  }, []);
};
