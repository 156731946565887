import { useRouter } from "next/router";

const USERCENTRICS_ID = process.env.NEXT_PUBLIC_USERCENTRICS_ID;
const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV;

let __usercentricsLoaded = false;

const loadUsercentrics = function (locale: string) {
  if (__usercentricsLoaded) return;

  let scriptTag = document.createElement("script");
  scriptTag.id = "usercentrics-cmp";
  scriptTag.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js";
  scriptTag.dataset.language = locale;
  scriptTag.dataset.settingsId = USERCENTRICS_ID;
  scriptTag.async = true;

  if (VERCEL_ENV !== "production") {
    scriptTag.dataset.version = "preview";
  }

  document.getElementsByTagName("head")[0].appendChild(scriptTag);

  __usercentricsLoaded = true;
};

export const useUsercentrics = () => {
  const { locale = "en" } = useRouter();

  // quietly ignore if USERCENTRICS_ID env var is not defined
  if (!USERCENTRICS_ID) return;

  const [shortLocale] = locale.split("-");

  // Load usercentrics script tag with a delay to improve pagespeed
  // performance scores
  setTimeout(() => loadUsercentrics(shortLocale), 5000);
};
