import { motion } from "framer-motion";
import { animationProps } from "./styles";

export type HyamMotionProps = {
  className?: string;
  children?: React.ReactNode;
  motionDisabled?: boolean;
};

/** Tag names including html tag names which are also supported by motion.<tag name> */
export type MotionTagNames =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "ul"
  | "ol"
  | "span"
  | "div";

/** Tag names including html tag names and prismic rich text editor component names */
type TagNames =
  | "h1"
  | "heading1"
  | "h2"
  | "heading2"
  | "h3"
  | "heading3"
  | "h4"
  | "heading4"
  | "h5"
  | "heading5"
  | "h6"
  | "heading6"
  | "p"
  | "paragraph"
  | "ul"
  | "ol"
  | "span"
  | "div";

const tagNamesToMotionNames: Record<TagNames, MotionTagNames> = {
  h1: "h1",
  heading1: "h1",
  h2: "h2",
  heading2: "h2",
  h3: "h3",
  heading3: "h3",
  h4: "h4",
  heading4: "h4",
  h5: "h5",
  heading5: "h5",
  h6: "h6",
  heading6: "h6",
  p: "p",
  paragraph: "p",
  ul: "ul",
  ol: "ol",
  div: "div",
  span: "span",
};

export const makeMotionComponent = (tag: TagNames) => {
  const MotionTag = tagNamesToMotionNames[tag];
  const Motion = motion[MotionTag];

  return (props: HyamMotionProps) => {
    const { motionDisabled = true } = props;

    if (motionDisabled) {
      return <MotionTag className={props.className}>{props.children}</MotionTag>;
    }

    return (
      <Motion className={props.className} {...animationProps[MotionTag]}>
        {props.children}
      </Motion>
    );
  };
};

export const MotionH1 = makeMotionComponent("h1");
export const MotionH2 = makeMotionComponent("h2");
export const MotionH3 = makeMotionComponent("h3");
export const MotionH4 = makeMotionComponent("h4");
export const MotionH5 = makeMotionComponent("h5");
export const MotionH6 = makeMotionComponent("h6");
export const MotionP = makeMotionComponent("p");
export const MotionUl = makeMotionComponent("ul");
export const MotionOl = makeMotionComponent("ol");
export const MotionDiv = makeMotionComponent("div");
export const MotionSpan = makeMotionComponent("span");
