import { ReactNode } from "react";
import { motion } from "framer-motion";
import {
  styles as moduleStyles,
  animationProps as moduleAnimationProps,
  HyamLayoutStyles,
  HyamLayoutAnimationProps,
} from "./styles";
import { withConfigure } from "hyam-core/utils/withConfigure";
import { useOnRouteChange } from "hyam-core/hooks/useOnRouteChange";

export type HyamLayoutProps = {
  header: ReactNode;
  children: ReactNode;
  footer: ReactNode;
  styles?: Partial<HyamLayoutStyles>;
  stylesReplace?: Partial<HyamLayoutStyles>;
  animationProps?: Partial<HyamLayoutAnimationProps>;
};

const _HyamLayout = (_props: HyamLayoutProps) => {
  const { header, footer, children, ...props } = _props;
  const styles = props.styles as HyamLayoutStyles;
  const animationProps = props.animationProps as HyamLayoutAnimationProps;

  const { routeStatusAction } = useOnRouteChange();
  const animations = animationProps[routeStatusAction];

  return (
    <motion.div className={styles.container} {...animations.container}>
      {header}
      <motion.div
        className={styles.children_container}
        {...animations.children_container}
      >
        {children}
      </motion.div>
      {footer}
    </motion.div>
  );
};

export const HyamLayout = withConfigure<HyamLayoutProps, HyamLayoutStyles>({
  componentName: "HyamLayout",
  Component: _HyamLayout,
  moduleStyles,
  moduleAnimationProps,
});
