export const styles = {
  all: "[&_br]:block [&_br]:!pb-1 lg:[&_br]:!pb-4 [&_br]:content-['___']",
  paragraph: "hyam-text-medium",
  heading1: "hyam-text-4xl",
  heading2: "hyam-text-4xl",
  heading3: "hyam-text-3xl",
  heading4: "hyam-text-2xl",
  heading5: "hyam-text-xl",
  heading6: "hyam-text-xl",
  listItem: "hyam-text-medium mb-6",
  hyperlink:
    "underline !mb-0 underline-offset-1 md:underline-offset-2",
  image: "w-full",
  oList: "list-decimal list-inside",
  oListItem: "",
  list: "list-disc list-outside ml-6",
};
