// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  anchor_section: dynamic(() => import("./AnchorSection")),
  anchor_section_navigation: dynamic(() => import("./AnchorSectionNavigation")),
  blog_post_featured: dynamic(() => import("./BlogPostFeatured")),
  blog_post_overview: dynamic(() => import("./BlogPostOverview")),
  contact_hero: dynamic(() => import("./ContactHero")),
  footer_link_column: dynamic(() => import("./FooterLinkColumn")),
  form_element: dynamic(() => import("./FormElement")),
  form_link: dynamic(() => import("./FormLink")),
  hero_cropped_image: dynamic(() => import("./HeroCroppedImage")),
  hero_full_image: dynamic(() => import("./HeroFullImage")),
  hero_simple: dynamic(() => import("./HeroSimple")),
  i_frame: dynamic(() => import("./IFrame")),
  image_slides: dynamic(() => import("./ImageSlides")),
  iubenda_document: dynamic(() => import("./IubendaDocument")),
  jobs_featured: dynamic(() => import("./JobsFeatured")),
  mailchimp_newsletter_signup: dynamic(() => import("./MailchimpNewsletterSignup")),
  portfolio_company_featured: dynamic(() => import("./PortfolioCompanyFeatured")),
  portfolio_company_overview: dynamic(() => import("./PortfolioCompanyOverview")),
  rich_text: dynamic(() => import("./RichText")),
  team_member_featured: dynamic(() => import("./TeamMemberFeatured")),
  team_member_overview: dynamic(() => import("./TeamMemberOverview")),
};
