import _ from "lodash";
import { FooterDocument } from "prismicio-types";
import {
  styles as moduleStyles,
  constants,
  HyamPrismicDocumentFooterStyles,
} from "./styles";
import { PrismicNextImage } from "@prismicio/next";
import {
  HyamPrismicRichText,
  renderSlicesToArray,
  HyamPrismicLink,
  HyamPrismicLangSwitchRow,
} from "hyam-prismic/components";
import { withConfigure } from "hyam-core/utils/withConfigure";

export type HyamPrismicDocumentFooterProps = {
  document: FooterDocument;
  styles?: Record<string, string>;
  socialMediaLinksPosition?: "top" | "bottom";
};

const SocialMediaLinks = (props: HyamPrismicDocumentFooterProps) => {
  const styles = props.styles as HyamPrismicDocumentFooterStyles;
  const data = props.document.data;

  return (
    <div className={styles.social_icons_container}>
      {_.map(data.social_media_links, ({ social_media_icon, social_media_link }, i) => (
        <HyamPrismicLink key={i} field={social_media_link}>
          <PrismicNextImage
            className={styles.social_media_icon}
            field={social_media_icon}
            width={constants.social_media_icon_width}
            height={constants.social_media_icon_width}
          />
        </HyamPrismicLink>
      ))}
    </div>
  );
};

const _HyamPrismicDocumentFooter = (props: HyamPrismicDocumentFooterProps) => {
  const styles = props.styles as HyamPrismicDocumentFooterStyles;

  const document = props.document;

  if (!document?.data) return null;

  const { data } = document;
  const { slices } = data;
  const { socialMediaLinksPosition = "top" } = props;

  return (
    <div className={styles.container}>
      <div className={styles.layout_container}>
        <div className={styles.top_container}>
          <div className={styles.brand_container}>
            <PrismicNextImage className={styles.brand_logo} field={data.logo} />
            <HyamPrismicRichText
              motionDisabled
              styles={{ all: styles.brand_description }}
              field={data.description}
            />
          </div>
          {socialMediaLinksPosition === "top" && <SocialMediaLinks {...props} />}
          <div className={styles.links_container}>
            {renderSlicesToArray({
              slices,
              styles: { all: { container: styles.link_col_container } },
            })}
          </div>
        </div>
        <div className={styles.bottom_container}>
          {socialMediaLinksPosition === "bottom" && <SocialMediaLinks {...props} />}
          <HyamPrismicRichText
            motionDisabled
            styles={{ all: styles.copyright_information }}
            field={data.copyright_information}
          />
          <HyamPrismicLangSwitchRow />
        </div>
      </div>
    </div>
  );
};

export const HyamPrismicDocumentFooter = withConfigure<
  HyamPrismicDocumentFooterProps,
  HyamPrismicDocumentFooterStyles
>({
  componentName: "HyamPrismicDocumentFooter",
  Component: _HyamPrismicDocumentFooter,
  moduleStyles,
});
