import { PrismicPreview, PrismicPreviewProps } from "@prismicio/next";

export const HyamPrismicPreview = (props: PrismicPreviewProps) => {
  console.log("___ env", process.env.NEXT_PUBLIC_VERCEL_ENV);

  // disable in production since it creates issues in pagespeed
  if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    return <>{props.children}</>;
  }

  return <PrismicPreview {...props} />;
};
