module.exports = [
  /*
  // DE
  { type: "home_page", path: "/", lang: "de-de" },
  { type: "page", path: "/:uid", lang: "de-de" },
  { type: "team_member", path: "/team-de/:uid", lang: "de-de" },
  { type: "portfolio_company", path: "/portfolio/:uid", lang: "de-de" },

  // EN
  { type: "home_page", path: "/en/", lang: "en" },
  { type: "page", path: "/en/:uid", lang: "en" },
  { type: "team_member", path: "/en/team-en/:uid", lang: "en" },
  { type: "portfolio_company", path: "/en/portfolio/:uid", lang: "en" },
  */

  // DE
  { type: "home_page", path: "/", lang: "de-de" },
  { type: "page", path: "/:uid", lang: "de-de" },
  { type: "team_member", path: "/team-de/:uid", lang: "de-de" },
  { type: "portfolio_company", path: "/portfolio/:uid", lang: "de-de" },
  { type: "job_post", path: "/jobs/:uid", lang: "de-de" },
  { type: "blog_post", path: "/blogs/:uid", lang: "de-de" },

  // EN
  { type: "home_page", path: "/en/", lang: "en" },
  { type: "page", path: "/en/:uid", lang: "en" },
  { type: "team_member", path: "/en/team-en/:uid", lang: "en" },
  { type: "portfolio_company", path: "/en/portfolio/:uid", lang: "en" },
  { type: "job_post", path: "/en/jobs/:uid", lang: "en" },
  { type: "blog_post", path: "/en/blogs/:uid", lang: "en" },
];
