import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { dispatchEvent } from "hyam-core/utils/eventTarget";

export type RouteStatusAction = "firstLoad" | "pageTransition";
export type RouteStatus = {
  routeStatusAction: RouteStatusAction;
};
const routeStatus: RouteStatus = {
  routeStatusAction: "firstLoad",
};

/**
 * Handler for next.js route changes.
 *
 * On route changes, it does the following:
 * - Hide navbar drawer
 * - Keep track of route status (return value), useful for different animations
 *   or other actions on firstLoad, pageTransition, etc.
 */
export const useOnRouteChange = () => {
  const router = useRouter();
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      dispatchEvent({ name: "hide_nav_bar_drawer" });

      if (routeStatus.routeStatusAction === "firstLoad") {
        routeStatus.routeStatusAction = "pageTransition";
        setChanged((val) => !val);
      }
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router, changed]);

  return routeStatus;
};
