import _urlJoin from "url-join";
import _ from "lodash";

const removeTrailingSlash = (str: string) => {
  return str.replace(/\/$/, "");
};

/**
 * Wrapper around url-join npm package.
 * - It replaces undefined or null for an empty string for all arguments.
 * - It removes trailing slash at the end of the returned string.
 */
export const urlJoin = (...args: (string | undefined | null)[]) => {
  const argsWithDefaultEmptyStr = _.map(args, (arg) => arg || "");

  return removeTrailingSlash(_urlJoin(argsWithDefaultEmptyStr));
};

/**
 * Root URL of the website. Examples:
 *
 * - https://www.seedandspeed.com            (production sites always use www)
 * - https://hyam-open.vercel.app  (mainly used for testing with Semrush)
 * - http://localhost:3000                   (localhost)
 */
export const rootUrl = (() => {
  const DOMAIN_URL = process.env.NEXT_PUBLIC_DOMAIN_URL || "";
  let url = `https://www.${process.env.NEXT_PUBLIC_DOMAIN_URL}`;

  if (DOMAIN_URL.match(/vercel.app$/)) {
    url = `https://${process.env.NEXT_PUBLIC_DOMAIN_URL}`;
  } else if (DOMAIN_URL.match(/localhost/)) {
    url = `http://${process.env.NEXT_PUBLIC_DOMAIN_URL}`;
  }

  return url;
})();
