import cn from "classnames";
export const styles = {
  container: cn(
    "fixed h-screen w-screen bg-hyam-gray-500/50 top-0 left-0",
    "flex justify-center items-center",
    "z-40",
  ),

  pop_up_container:
    "w-86 bg-hyam-white rounded-lg",
  close_icon: "cursor-pointer",

  top_container: cn(
    "flex p-4 justify-start gap-x-6",
    "border-b border-hyam-gray-200",
  ),

  middle_container: cn(
    "px-4 py-6",
    "border-b border-hyam-gray-200",
  ),

  bottom_container: cn(
    "grid grid-flow-col justify-stretch gap-x-4",
    "p-4",
  ),

  headline: "hyam-text-h4",
  select_language: "hyam-text-caption",

  close_svg: "shrink-0 cursor-pointer",

  button_prismic_link_wrapper: "flex flex-1",
  button_container: "px-0 flex-1",
};

export type HyamPrismicLanguageSwitchModalStyles =
  typeof styles;
