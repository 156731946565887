import _ from "lodash";
import Cookie from "js-cookie";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import { useState, useEffect, useCallback } from "react";
import { mergeStyles } from "hyam-core/utils";
import { styles as moduleStyles, HyamPrismicLanguageSwitchModalStyles } from "./styles";
import {
  addEventListener,
  removeEventListener,
  dispatchEvent,
} from "hyam-core/utils/eventTarget";
import { lock, unlock } from "hyam-core/utils/bodyScrollLock";
import { HyamRadioGroup } from "hyam-core/components";
import { HyamPrismicButton } from "hyam-prismic/components";
import Close from "hyam-core/svg/x-20px.svg";
import globalData from "data/globalData.json";
import { linkResolverAltLang } from "hyam-prismic/utils/linkResolver";
import { AnyPageDocument } from "hyam-prismic/types";

const { all_languages } = globalData;

export type HyamPrismicLanguageSwitchModalProps = {
  styles?: Partial<HyamPrismicLanguageSwitchModalStyles>;
  stylesReplace?: Partial<HyamPrismicLanguageSwitchModalStyles>;
  page: AnyPageDocument;
};

export const HyamPrismicLanguageSwitchModal = (
  props: HyamPrismicLanguageSwitchModalProps,
) => {
  const {
    styles: propsStyles = {},
    stylesReplace: propsStylesReplace = {},
    page,
  } = props;
  const styles = mergeStyles(
    moduleStyles,
    propsStyles,
    propsStylesReplace,
    "HyamPrismicLanguageSwitchModal",
  );
  const [show, setShow] = useState(false);
  const router = useRouter();
  const [selectedLang, setSelectedLang] = useState(router.locale);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const onShow = () => {
      setSelectedLang(router.locale);
      lock("HyamPrismicLanguageSwitchModal");
      setShow(true);
    };
    addEventListener({ name: "show_language_switch_modal", cb: onShow });

    return () => removeEventListener({ name: "show_language_switch_modal", cb: onShow });
  }, [setShow, router.locale]);

  const doHide = () => {
    setShow(false);
    unlock("HyamPrismicLanguageSwitchModal");
  };

  const onSave = useCallback(() => {
    doHide();

    if (selectedLang !== router.locale) {
      dispatchEvent({ name: "hide_nav_bar_drawer" });
      const url = linkResolverAltLang(page, selectedLang as string);
      Cookie.set("NEXT_LOCALE", selectedLang || "");
      router.push(url, url, { locale: selectedLang });
    }
  }, [selectedLang, router.locale]);

  if (!show) return null;

  return (
    <div className={styles.container}>
      <div className={styles.pop_up_container}>
        <div className={styles.top_container}>
          <div className={styles.close_icon} onClick={doHide}>
            <h4 className={styles.headline}>
              <FormattedMessage id="language_switch_headline" />
            </h4>
          </div>
          <Close className={styles.close_svg} onClick={doHide} />
        </div>
        <div className={styles.middle_container}>
          <p className={styles.select_language}>
            <FormattedMessage id="language_switch_subheadline" />
          </p>
          <HyamRadioGroup
            name="languages"
            onChange={(lang) => setSelectedLang(lang)}
            items={_.map(all_languages, (lang) => {
              const langShort = lang.split("-")[0];
              return {
                label: formatMessage({ id: "language_" + langShort }),
                id: lang,
                checked: selectedLang === lang,
              };
            })}
          />
        </div>
        <div className={styles.bottom_container}>
          <HyamPrismicButton
            styles={{
              container_contained: styles.button_container,
              container_outline: styles.button_container,
              container_text: styles.button_container,
              prismic_link_wrapper: styles.button_prismic_link_wrapper,
            }}
            variant="Outline Button"
            onClick={doHide}
          >
            <FormattedMessage id="button_cancel_label" />
          </HyamPrismicButton>
          <HyamPrismicButton
            styles={{
              prismic_link_wrapper: styles.button_prismic_link_wrapper,
              container_contained: styles.button_container,
              container_outline: styles.button_container,
              container_text: styles.button_container,
            }}
            onClick={onSave}
          >
            <FormattedMessage id="button_save_label" />
          </HyamPrismicButton>
        </div>
      </div>
    </div>
  );
};
