import { useEffect, useState } from "react";
import Script from "next/script";
import { useInitialUserEvent } from "hyam-core/hooks/useInitialUserEvent";

const getIFrameURL = ({ id, server, environment, auth }: HyamGTMProps) => {
  const BASE_URL = `https://${server}/ns.html`;

  const queryParams = new URLSearchParams({ id });

  if (auth) {
    queryParams.append("gtm_auth", auth);
  }

  if (environment) {
    queryParams.append("gtm_preview", `env-${environment}`);
    queryParams.append("gtm_cookies_win", "x");
  }

  return `${BASE_URL}?${queryParams.toString()}`;
};

const getScriptQueryString = (auth?: string, environment?: string) => {
  const string = "";

  if (auth) string.concat(`&gtm_auth=${auth}`);
  if (environment) string.concat(`&gtm_preview=env-${environment}&gtm_cookies_win=x`);

  return string;
};

export type HyamGTMProps = {
  id: string;
  server?: string;
  auth?: string;
  environment?: string;
};

const loadWithDelay = process.env.NEXT_PUBLIC_GTM_DELAY_LOAD === "true";

export const HyamGTM = ({
  id,
  server = "www.googletagmanager.com",
  auth,
  environment,
}: HyamGTMProps) => {
  const [ready, setReady] = useState(!loadWithDelay);

  useEffect(() => {
    const handler = () => setReady(true);
    const timeoutId = setTimeout(handler, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useInitialUserEvent(() => setReady(true));

  if (!ready) return null;

  return (
    <>
      <noscript>
        <iframe
          title="gtm"
          src={getIFrameURL({ id, server, auth, environment })}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://${server}/gtm.js?id='+i+dl ${getScriptQueryString(
              auth,
              environment,
            )};f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${id}');
          `,
        }}
      />
    </>
  );
};
