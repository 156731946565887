import "event-target-polyfill";
import { useEffect, DependencyList } from "react";

export type EventUndefinedDetail = undefined;

export type EventOnScrollWindowName = "on_scroll_window";
export type EventOnScrollWindowDetail = {
  scrollTop: number;
  direction: "up" | "down";
  thresholdUp: number;
};

export type EventShowLanguageSwitchModalName = "show_language_switch_modal";
export type EventShowLanguageSwitchModalDetail = undefined;

export type EventShowNavBarDrawer = "show_nav_bar_drawer";
export type EventShowNavBarDrawerDetail = undefined;

export type EventHideNavBarDrawer = "hide_nav_bar_drawer";
export type EventHideNavBarDrawerDetail = undefined;

export type EventNavBarIsHidden = "nav_bar_is_hidden";
export type EventNavBarIsVisible = "nav_bar_is_visible";

/** When scrolling state changed to locked or unlocked */
export type EventScrollLockChange = "scroll_lock_change";
export type EventScrollLockChangeDetail = undefined;

export type DispatchEventProps =
  | {
      name: EventOnScrollWindowName;
      detail: EventOnScrollWindowDetail;
    }
  | {
      name: EventShowLanguageSwitchModalName;
      detail?: EventShowLanguageSwitchModalDetail;
    }
  | {
      name: EventShowNavBarDrawer;
      detail?: EventShowNavBarDrawerDetail;
    }
  | {
      name: EventHideNavBarDrawer;
      detail?: EventHideNavBarDrawerDetail;
    }
  | {
      name: EventNavBarIsVisible;
      detail?: EventUndefinedDetail;
    }
  | {
      name: EventNavBarIsHidden;
      detail?: EventUndefinedDetail;
    }
  | {
      name: EventScrollLockChange;
      detail?: EventScrollLockChangeDetail;
    };

export type AddEventListenerProps =
  | {
      name: EventOnScrollWindowName;
      cb: ({ detail }: { detail: EventOnScrollWindowDetail }) => void; // eslint-disable-line
    }
  | {
      name: EventShowLanguageSwitchModalName;
      cb: () => void;
    }
  | {
      name: EventShowNavBarDrawer;
      cb: () => void;
    }
  | {
      name: EventHideNavBarDrawer;
      cb: () => void;
    }
  | {
      name: EventNavBarIsHidden;
      cb: () => void;
    }
  | {
      name: EventNavBarIsVisible;
      cb: () => void;
    }
  | {
      name: EventScrollLockChange;
      cb: () => void;
    };

let eventTarget: EventTarget;
const showConsoleLogs = false;

const init = () => {
  if (!eventTarget) {
    showConsoleLogs && console.log("___ eventTarget init");
    eventTarget = new EventTarget();
  }
};

/**
 * Wrapper to dispatch only events in EventNames type
 */
export const dispatchEvent = (props: DispatchEventProps) => {
  init();

  showConsoleLogs && console.log("___ dispatchEvent", props.name);
  // https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events#adding_custom_data_%E2%80%93_customevent
  eventTarget.dispatchEvent(new CustomEvent(props.name, { detail: props?.detail }));
};

/**
 * Wrapper to only listen to events in EventNames type
 */
export const addEventListener = (props: AddEventListenerProps) => {
  init();

  showConsoleLogs && console.log("___ addEventListener", props.name, true);
  eventTarget.addEventListener(props.name, props.cb as any, true);
};

/**
 * Wrapper to only remove events in EventNames type
 */
export const removeEventListener = (props: AddEventListenerProps) => {
  init();

  showConsoleLogs && console.log("___ removeEventListener", props.name);
  eventTarget.removeEventListener(props.name, props.cb as any, true);
};

export const useEventListener = (
  props: AddEventListenerProps,
  dependencyList: DependencyList,
) => {
  useEffect(() => {
    addEventListener(props);

    return () => {
      removeEventListener(props);
    };
  }, dependencyList);
};
