import _ from "lodash";
import cn from "classnames";
import { twMerge } from "hyam-core/utils/tailwindMerge";

/**
 * Adds componentName to the classNames to make it easier to debug styles in
 * the dev console.
 * NOTE: style keys are not added on production
 */
export function addStylesKeys<T extends Record<string, string>>(
  styles: T,
  componentName?: string,
): T {
  if (process.env.NEXT_PUBLIC_SHOW_STYLES_KEYS !== "enabled") return styles;

  return _.mapValues(styles, (style, styleKey) => {
    const key = componentName ? componentName + "_" + styleKey : "styleKey_" + styleKey;
    // styleKey already added, ingore
    if (style.includes(key)) return style;

    return cn(key, style);
  }) as T;
}

/**
 * Replaces and then merges styles class names
 */
export function mergeStyles<T extends Record<string, string>>(
  _stylesSource: T,
  stylesMerge: Partial<T> = {},
  stylesReplace: Partial<T> = {},
  componentName?: string,
) {
  let stylesSource: Partial<T> = _stylesSource;

  // If stylesSource is empty, create an empty stylesSource with all style
  // keys in stylesMerge and stylesReplace.
  if (_.isEmpty(stylesSource)) {
    stylesSource = _.mapValues(
      { ...stylesMerge, ...stylesReplace },
      () => "",
    ) as Partial<T>;
  }

  const replaced = _.mapValues(stylesSource, (val, key) => {
    // replace source style with stylesReplace value if found
    if (_.isString(stylesReplace?.[key])) return stylesReplace?.[key];

    return val;
  }) as T;

  const merged = _.mapValues(replaced, (val, key) => {
    if (val && stylesMerge?.[key]) return twMerge(val, stylesMerge[key]);

    return cn(val, stylesMerge?.[key]);
  }) as T;

  return addStylesKeys(merged, componentName) as T;
}
