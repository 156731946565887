import _ from "lodash";
import { mergeStyles } from "hyam-core/utils";
import { styles as moduleStyles, HyamRadioGroupStyles } from "./styles";

export type HyamRadioGroupProps = {
  styles?: Partial<HyamRadioGroupStyles>;
  name: string;
  onChange?: (value: string) => void; // eslint-disable-line
  items: {
    label: string;
    id: string;
    value?: string;
    checked?: boolean;
  }[];
};

export const HyamRadioGroup = (props: HyamRadioGroupProps) => {
  const { styles: propsStyles = {}, items, name, onChange = _.noop } = props;
  const styles = mergeStyles(moduleStyles, propsStyles);

  return (
    <div
      className={styles.container}
      onChange={(event) => {
        const value = (event?.target as any)?.value;
        onChange(value);
      }}
    >
      {_.map(items, ({ label, id, value, checked }) => (
        <div className={styles.item_container} key={id}>
          <input
            className={styles.input}
            type="radio"
            id={id}
            name={name}
            value={value || id}
            checked={checked}
          />
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
};
