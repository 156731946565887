import { useContext, useEffect, useState, useRef, ComponentType } from "react";
import { motion } from "framer-motion";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import _ from "lodash";
import { NavBarDocument } from "prismicio-types";
import {
  constants,
  styles as moduleStyles,
  animationProps as moduleAnimationProps,
  HyamPrismicDocumentNavBarStyle,
} from "./styles";
import { PrismicNextImage } from "@prismicio/next";
import HamburgerOpen from "hyam-core/svg/hamburger-open-24px.svg";
import {
  HyamPrismicLink,
  HyamPrismicButton,
  HyamPrismicDocumentNavBar_Drawer,
  HyamPrismicLangSwitchDropdown,
  HyamPrismicLangSwitchRow,
  HyamPrismicGlobalData,
} from "hyam-prismic/components";
import {
  useEventListener,
  dispatchEvent,
  EventOnScrollWindowDetail,
} from "hyam-core/utils/eventTarget";
import { useOnRouteChange } from "hyam-core/hooks/useOnRouteChange";
import { useRouter } from "next/router";
import { withConfigure } from "hyam-core/utils/withConfigure";
import { classNamesWithMerge as cn } from "hyam-core/utils/tailwindMerge";

export type HyamPrismicDocumentNavBarProps = {
  document: NavBarDocument;
  styles?: Partial<HyamPrismicDocumentNavBarStyle>;
  stylesReplace?: Partial<HyamPrismicDocumentNavBarStyle>;
  langSwitchUi?: "dropdown" | "row";
  Hamburger?: ComponentType;
};

type UseScrollViewportHeightProps = {
  isBelowViewportHeight: () => void;
  isAboveViewportHeight: () => void;
};

const useScrollViewportHeight = (props: UseScrollViewportHeightProps) => {
  const isBelow = useRef(false);

  useEventListener(
    {
      name: "on_scroll_window",
      cb: ({ detail }: { detail: EventOnScrollWindowDetail }) => {
        const isBelowNew = detail.scrollTop > window.innerHeight;

        if (isBelowNew === isBelow.current) return;

        if (isBelowNew) {
          props.isBelowViewportHeight();
        } else {
          props.isAboveViewportHeight();
        }

        isBelow.current = isBelowNew;
      },
    },
    [],
  );
};

const _HyamPrismicDocumentNavBar = (props: HyamPrismicDocumentNavBarProps) => {
  const { document, langSwitchUi = "dropdown", Hamburger = HamburgerOpen } = props;

  const styles = props.styles as HyamPrismicDocumentNavBarStyle;
  const { page } = useContext(HyamPrismicGlobalData);

  const nav_bar_color = page?.data?.nav_bar_color || "Default";
  const nav_bar_hide_buttons = page?.data?.nav_bar_hide_buttons || false;

  const [showBar, setShowBar] = useState(true);
  const { routeStatusAction } = useOnRouteChange();
  const { data } = document;
  const [isScrollTop, setIsScrollTop] = useState(true);
  const isScrollTopRef = useRef(isScrollTop);
  isScrollTopRef.current = isScrollTop;

  useScrollPosition(({ currPos }) => {
    const newIsScrollTop = currPos.y > constants.scrollTopThreshold;

    // no need to apply, value didn't change
    if (newIsScrollTop === isScrollTopRef.current) return;

    setIsScrollTop(newIsScrollTop);
  });

  useScrollViewportHeight({
    isAboveViewportHeight: () => console.log("___ isAboveViewportHeight"),
    isBelowViewportHeight: () => console.log("___ isBelowViewportHeight"),
  });

  // show/hide bar
  useEventListener(
    {
      name: "on_scroll_window",
      cb: ({ detail }: { detail: EventOnScrollWindowDetail }) => {
        if (
          detail.scrollTop < constants.hideDesktopNavBarThreshold ||
          (detail.direction === "up" && detail.thresholdUp > constants.showThreshold)
        ) {
          if (!showBar) {
            setShowBar(true);
            dispatchEvent({ name: "nav_bar_is_visible" });
          }
        } else {
          if (showBar) {
            setShowBar(false);
            dispatchEvent({ name: "nav_bar_is_hidden" });
          }
        }
      },
    },
    [showBar],
  );

  const { asPath } = useRouter();
  const animations = moduleAnimationProps[routeStatusAction];
  const [animationsContainer, setAnimationsContainer] = useState(animations.container);

  useEffect(() => {
    setAnimationsContainer({
      ...moduleAnimationProps.onScroll.container,
      animate: showBar ? "show" : "hidden",
    });
  }, [routeStatusAction, showBar]);

  const styles_container = cn(styles.container, {
    [styles.container_scroll_top]: isScrollTop,
    [styles.container_color_primary]: nav_bar_color === "Primary",
  });

  if (!document?.data) return <div>HyamPrismicDocumentNavBar: Missing data</div>;
  return (
    <>
      <motion.div className={styles_container} {...animationsContainer}>
        {/* bg */}
        <div className="absolute h-full hyam-layout-full-width top-0 bg-white -z-10"></div>
        <h2 className="hidden lg:block lg:hyam-text-small !mb-0">{data.tagline}</h2>
        <div className={styles.logo_container}>
          <HyamPrismicLink href="/">
            <PrismicNextImage
              height={constants.logoHeight}
              className={styles.logo}
              field={data.logo}
            />
          </HyamPrismicLink>
          <Hamburger
            className={styles.hamburger_sm}
            onClick={() => dispatchEvent({ name: "show_nav_bar_drawer" })}
          />
        </div>
        <div className={styles.links_container}>
          {_.map(data.nav_links, ({ link_label, link }, i) => {
            const isActive =
              link?.link_type === "Any"
                ? false
                : asPath.indexOf((link as any)?.uid || "") > -1;

            //TODO: Remove workaround
            const isConctactPage = /\w*contact\w*/i.test(link_label as string);

            return (
              <HyamPrismicLink
                className={cn(styles.link, { [styles.link_active]: isActive })}
                key={i}
                field={link}
                // TODO: Remove
                href={isConctactPage ? "/contact" : undefined}
              >
                {link_label}
              </HyamPrismicLink>
            );
          })}
        </div>
        <div className={styles.buttons_container}>
          {langSwitchUi === "dropdown" ? (
            <HyamPrismicLangSwitchDropdown />
          ) : (
            <HyamPrismicLangSwitchRow />
          )}
          {!nav_bar_hide_buttons &&
            _.map(data.buttons, ({ button_link, button_label, button_style }, i) => (
              <HyamPrismicButton
                key={i}
                field={button_link}
                variant={button_style}
                styles={{ container: styles.button }}
              >
                {button_label}
              </HyamPrismicButton>
            ))}
        </div>
        <Hamburger
          className={styles.hamburger_md}
          onClick={() => dispatchEvent({ name: "show_nav_bar_drawer" })}
        />
      </motion.div>
      <HyamPrismicDocumentNavBar_Drawer document={props.document} />
    </>
  );
};

export const HyamPrismicDocumentNavBar = withConfigure<
  HyamPrismicDocumentNavBarProps,
  HyamPrismicDocumentNavBarStyle
>({
  componentName: "HyamPrismicDocumentNavBar",
  Component: (props: HyamPrismicDocumentNavBarProps) => {
    if (!props?.document?.data) return null;
    return <_HyamPrismicDocumentNavBar {...props} />;
  },
  moduleStyles,
});
