import _ from "lodash";
import { lock as _lock, unlock as _unlock } from "tua-body-scroll-lock";
import { dispatchEvent } from "./eventTarget";

let isLocked = false;
const showConsoleLogs = false;

// Keep track of all locks, unlock can only happen if all locks have been cleared.
// For example, if the nav bar drawer and the lang switch modal are opened.
// - Then, both of those components called lock.
// - Unlock is only possible after those two components call unlock()
const lockIds: Record<string, boolean> = {};

export const lock = (id: string) => {
  lockIds[id] = true;

  // ignore double locks
  if (isLocked) return;

  showConsoleLogs && console.log("___ bodyScrollLock lock");
  dispatchEvent({ name: "scroll_lock_change" });
  _lock();
  isLocked = true;
};

export const unlock = (id: string) => {
  if (lockIds?.[id]) delete lockIds?.[id];

  // ignore double unlocks
  if (!isLocked) return;

  // not all lock ids are cleared, cannot unlock yet
  if (_.find(lockIds, (lockId) => lockId)) return;

  showConsoleLogs && console.log("___ bodyScrollLock unlock");
  dispatchEvent({ name: "scroll_lock_change" });
  _unlock();
  isLocked = false;
};
