import _ from "lodash";
import {
  JobPostDocument,
  PortfolioCompanyDocument,
  SiteConfigDocumentData,
  TeamMemberDocument,
} from "prismicio-types";
import { AnyPageDocument } from "hyam-prismic/types";
import { BlogPostDocument, HomePageDocument, AuthorDocument } from "prismicio-types";
import NextHead from "next/head";
import { asText } from "@prismicio/richtext";
import { urlJoin, rootUrl } from "hyam-core/utils/url";
import globalData from "data/globalData.json";
import titleCase from "voca/title_case";
import type {
  WithContext,
  BlogPosting,
  WebPage,
  Organization,
  BreadcrumbList,
  Person,
  JobPosting,
} from "schema-dts";

/** Two digits default locale, example: "en" */
const [defaultShortLocale] = globalData.default_language.split("-");

const isDefaultLocale = (page: AnyPageDocument) => {
  const [pageShortLocale] = page.lang.split("-");
  return pageShortLocale === defaultShortLocale;
};

/**
 * Removes two digits locale slug from page.url
 * Example: if page.url = '/de/team/', it will return '/team/'
 */
const getPageUrlWithoutLocale = (page: AnyPageDocument) => {
  return isDefaultLocale(page) ? page.url : page.url.slice(3, page.url.length);
};

/**
 * Returns an array of all parts of the page url.
 * Example: ["https://hyam-open.vercel.app/de", "team", "santiago-ferreira"]
 */
const getUrlArray = (page: AnyPageDocument) => {
  const rootUrlLocalized = isDefaultLocale(page) ? rootUrl : urlJoin(rootUrl, page.lang);

  const pageUrl = getPageUrlWithoutLocale(page);
  const slugsArray = _.compact((pageUrl || "").split("/"));

  return [rootUrlLocalized, ...slugsArray];
};

/**
 * Get localized site config object from globalData merged with items in
 * default language if they don't exist in alt language.
 */
export const getLocalizedSiteConfig = (shortLocale: string) => {
  const siteConfigDefaultLang = (globalData.site_config as any)?.[
    defaultShortLocale
  ] as SiteConfigDocumentData;

  if (shortLocale === defaultShortLocale) {
    return siteConfigDefaultLang;
  }

  const siteConfigAltLang = (globalData.site_config as any)?.[
    shortLocale
  ] as SiteConfigDocumentData;

  const merged = _.mapValues(siteConfigAltLang, (val, key) => {
    return _.isEmpty(val) ? (siteConfigDefaultLang as any)?.[key] : val;
  });

  return merged as SiteConfigDocumentData;
};

const getDefaultLocaleSiteConfig = () => {
  const siteConfig = (globalData.site_config as any)?.[
    defaultShortLocale
  ] as SiteConfigDocumentData;

  return siteConfig;
};

/**
 * Get schema for a BlogPostDocument page
 */
const getBlogPostSchema = (page: BlogPostDocument) => {
  const authorDocument = page.data.author as any as AuthorDocument;
  const authorFirstName = authorDocument?.data?.first_name;
  const authorLastName = authorDocument?.data?.last_name;
  const author: Person | undefined =
    authorFirstName && authorLastName
      ? {
          "@type": "Person",
          name: `${authorFirstName} ${authorLastName}`,
        }
      : undefined;

  const schema: WithContext<BlogPosting> = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: asText(page.data.headline),
    image: page.data.feature_image.url || "",
    url: urlJoin(rootUrl, page.url),
    datePublished: page.first_publication_date,
    dateCreated: page.first_publication_date,
    dateModified: page.last_publication_date,
    description: asText(page.data.lead) || "",
    author,
  };

  return schema;
};

/**
 * Get schema for a generic page
 */
const getGenericPageSchema = (page: AnyPageDocument) => {
  const uid = page.uid || "";

  const schema: WithContext<WebPage> = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: titleCase(uid.replaceAll("-", " ")),
    url: urlJoin(rootUrl, page.url),
    image: page.data.meta_image.url || "",
    description: page.data.meta_description || "",
  };

  return schema;
};

/**
 * Get schema for a TeamMemberDocument page
 */
const getTeamMemberSchema = (page: TeamMemberDocument) => {
  const schema: WithContext<Person> = {
    "@context": "https://schema.org",
    "@type": "Person",
    name: page.data.first_name + " " + page.data.last_name,
    url: urlJoin(rootUrl, page.url),
    image: page.data.meta_image.url || "",
    description: page.data.meta_description || "",
    jobTitle: page.data.role || "",
  };

  return schema;
};

/**
 * Get schema for a JobPostDocument page
 */
const getJobPostSchema = (page: JobPostDocument) => {
  const siteConfig = getDefaultLocaleSiteConfig();

  const schema: WithContext<JobPosting> = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    datePosted: page.first_publication_date,
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: page.data.street_address || "",
        addressLocality: page.data.city || "",
        addressRegion: page.data.state || "",
        postalCode: page.data.postal_code || "",
        addressCountry: page.data.country || "",
      },
    },
    description: asText(page.data.lead),
    title: asText(page.data.headline),
    hiringOrganization: {
      "@type": "Organization",
      name: siteConfig.company_name || "",
    },
  };

  return schema;
};

/**
 * Get schema for a JobPostDocument page
 */
const getPortfolioCompanySchema = (page: PortfolioCompanyDocument) => {
  const schema: WithContext<Organization> = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: page.data.company_name || "",
    url: urlJoin(rootUrl, page.url),
    image: page.data.meta_image?.url || "",
    logo: page.data.company_logo?.url || "",
    sameAs: [(page.data.website as any)?.url || ""],
  };

  return schema;
};

/**
 * Get schema for the HomePageDocument
 */
const getHomePageSchema = (page: HomePageDocument) => {
  const siteConfig = getDefaultLocaleSiteConfig();

  const schema: WithContext<Organization> = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: siteConfig.company_name || "",
    legalName: siteConfig.company_legal_name || "",
    image: page.data.meta_image.url || "",
    url: urlJoin(rootUrl, page.url),
    logo: siteConfig.company_logo.url || "",
    foundingDate: siteConfig.company_founding_date || "",
    founders: _.map(siteConfig.company_founders, (founder) => ({
      "@type": "Person",
      name: founder.company_founder_full_name || "",
    })),
    address: {
      "@type": "PostalAddress",
      streetAddress: siteConfig.company_address_street || "",
      addressLocality: siteConfig.company_address_city || "",
      addressRegion: siteConfig.company_address_region || "",
      postalCode: siteConfig.company_address_postal_code || "",
      addressCountry: siteConfig.company_address_country || "",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: siteConfig.company_phone_number || "",
      email: siteConfig.company_email || "",
    },
    sameAs: _.flatten([
      _.map(
        siteConfig.company_social_media_links,
        (item) => (item.social_media_link as any)?.url || "",
      ),
      _.map(
        siteConfig.company_web_page_links,
        (item) => (item.web_page_link as any)?.url || "",
      ),
    ]),
  };

  return schema;
};

const getBreadcrumbListSchema = (page: AnyPageDocument) => {
  const urlArray = getUrlArray(page);
  let slugHistory: string[] = [];

  const schema: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: urlArray.map((slug, index) => {
      slugHistory.push(slug);

      return {
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@id": urlJoin(...slugHistory),
          name: index === 0 ? "Home" : titleCase(slug.replaceAll("-", " ")),
        },
      };
    }),
  };

  return schema;
};

const getAllSchemas = (page: AnyPageDocument) => {
  const schemas = [];

  switch (page.type) {
    case "home_page":
      schemas.push(getHomePageSchema(page as any as HomePageDocument));
      break;
    case "blog_post":
      schemas.push(getBlogPostSchema(page as any as BlogPostDocument));
      break;
    case "team_member":
      schemas.push(getTeamMemberSchema(page as any as TeamMemberDocument));
      break;
    case "job_post":
      schemas.push(getJobPostSchema(page as any as JobPostDocument));
      break;
    case "portfolio_company":
      schemas.push(getPortfolioCompanySchema(page as any as PortfolioCompanyDocument));
      break;
    default:
      schemas.push(getGenericPageSchema(page));
  }

  schemas.push(getBreadcrumbListSchema(page));

  return schemas;
};

export type HyamPrismicJsonLDProps = {
  page: AnyPageDocument;
  defaultLanguage: string;
};

export const HyamPrismicJsonLD = (props: HyamPrismicJsonLDProps) => {
  const schemas = getAllSchemas(props.page);

  return (
    <NextHead>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemas, null, 2),
        }}
      ></script>
    </NextHead>
  );
};
