import cn from "classnames";
import { animationVariants as av } from "hyam-core/animation/defaults";

export const styles = {
  container: "",

  button_container: cn(
    "flex",
    "cursor-pointer",
    "select-none",

    "z-10",
    "relative",
  ),
  button_label: cn(
    "text-hyam-primary-600",
    "hyam-text-body",
    "w-6",
  ),
  button_label_dark: "text-hyam-white",
  globe_icon: "mr-2 stroke-hyam-black",
  globe_icon_dark: "stroke-hyam-white",
  chevron_icon: cn("ml-2", "stroke-hyam-black"),
  chevron_icon_dark: "stroke-hyam-white",

  dropdown_container: cn(
    "absolute",
    "bg-hyam-white",
    "z-0",
  ),
  dropdown_container_dark: "bg-transparent",

  lang_option_container: cn(
    "pt-2",
    "pb-1",
    "px-8",
    "cursor-pointer",
    "select-none",
  ),
  lang_option_label: cn(
    "text-hyam-primary-600",
    "hyam-text-body",
    "w-6",
    "leading-none",
    "hover:underline",
  ),
  lang_option_label_dark: "text-hyam-white",
};

export type HyamPrismicLangSwitchDropdownStyles =
  typeof styles;

export const animationProps = {
  container: {
    initial: "close",
    variants: { open: {}, close: {} },
  },
  dropdown_container: {
    variants: {
      close: av.dropdown_close,
      open: av.dropdown_open,
    },
  },
  chevron_icon: {
    variants: {
      close: av.rotate_180_on,
      open: av.rotate_180_off,
    },
  },
};
