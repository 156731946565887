import {
  MotionProps,
  Variant,
} from "framer-motion";

const offText: Variant = {
  y: 20,
  opacity: 0.3,
};

const onText: Variant = {
  y: 0,
  opacity: 1,
  transition: {
    duration: 0.6,
    ease: "easeOut",
  },
};

const offDiv: Variant = {
  y: 20,
};

const onDiv: Variant = {
  y: 0,
  transition: {
    duration: 0.6,
    ease: "easeOut",
  },
};

const animationPropText: MotionProps = {
  whileInView: "on",
  initial: "off",
  viewport: { once: true },
  variants: {
    off: offText,
    on: onText,
  },
};

const animationPropDiv: MotionProps = {
  whileInView: "on",
  initial: "off",
  viewport: { once: true },
  variants: {
    off: offDiv,
    on: onDiv,
  },
};

export const animationProps = {
  h1: animationPropText,
  h2: animationPropText,
  h3: animationPropText,
  h4: animationPropText,
  h5: animationPropText,
  h6: animationPropText,
  ol: animationPropText,
  ul: animationPropText,
  p: animationPropText,
  span: animationPropText,
  div: animationPropDiv,
};
