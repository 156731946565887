import { AnimationProps, Variant } from "framer-motion";
import _config from "root/config/animation";

const config = (_config || { props: {}, variants: {} }) as {
  props: Record<string, AnimationProps>;
  variants: Record<string, Variant>;
};

export type AnimationVariantsKeys =
  | "first_load_off"
  | "first_load_on"
  | "page_transition_off"
  | "page_transition_on"
  | "image_zoom_off"
  | "image_zoom_on"
  | "y_bottom_small_hidden"
  | "y_bottom_small_show"
  | "y_top_full_hidden"
  | "y_top_full_show"
  | "accordion_open"
  | "accordion_close"
  | "dropdown_open"
  | "dropdown_close"
  | "rotate_180_off"
  | "rotate_180_on";

export type AnimationVariants = Record<AnimationVariantsKeys, Variant>;

export const animationVariants: AnimationVariants = {
  first_load_off: {
    opacity: 0.05,
  },

  first_load_on: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },

  page_transition_off: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },

  page_transition_on: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },

  image_zoom_off: {
    y: 50,
    scale: 1.1,
  },

  image_zoom_on: {
    y: 0,
    scale: 1,
    transition: {
      duration: 2,
      ease: [0.17, 0.17, 0, 0.99],
    },
  },

  y_bottom_small_hidden: {
    y: "30%",
    opacity: 0,
  },

  y_bottom_small_show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.17, 0.17, 0, 0.99],
    },
  },

  y_top_full_hidden: {
    y: "-100%",
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },

  y_top_full_show: {
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },

  // TODO maxHeight approach doesn't work so well, need to find a different approach
  accordion_open: {
    maxHeight: "1000px",
    transition: {
      duration: 0.75,
      ease: "easeInOut",
    },
  },

  accordion_close: {
    maxHeight: "0px",
    transition: {
      duration: 0.75,
      ease: "easeInOut",
    },
  },

  dropdown_open: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  },

  dropdown_close: {
    opacity: 0,
    translateY: -10,
    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  },

  rotate_180_off: {
    rotate: 0,
    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  },

  rotate_180_on: {
    rotate: 180,
    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  },
};

export const firstLoadContainer: AnimationProps = config?.props?.firstLoadContainer || {
  variants: {
    hidden: animationVariants.first_load_off,
    show: animationVariants.first_load_on,
  },
  initial: "hidden",
  animate: "show",
};

export const pageTransitionContainer: AnimationProps = config?.props
  ?.pageTransitionContainer || {
  variants: {
    hidden: animationVariants.page_transition_off,
    show: animationVariants.page_transition_on,
  },
  initial: "hidden",
  animate: "show",
};

export const imageZoomY: AnimationProps = config?.props?.imageZoomY || {
  variants: {
    hidden: animationVariants.image_zoom_off,
    show: animationVariants.image_zoom_on,
  },
  initial: "hidden",
  animate: "show",
};

export const yBottomSmall: AnimationProps = config?.props?.yBottomSmall || {
  variants: {
    hidden: animationVariants.y_bottom_small_hidden,
    show: animationVariants.y_bottom_small_show,
  },
  initial: "hidden",
  animate: "show",
};

export const yTopFull: AnimationProps = config?.props?.yTopFull || {
  variants: {
    hidden: animationVariants.y_top_full_hidden,
    show: animationVariants.y_top_full_show,
  },
  initial: "show",
  animate: "show",
};
