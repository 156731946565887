import { mergeStyles } from "./mergeStyles";
import { AnimationProps } from "framer-motion";
import { mergeAnimationProps } from "hyam-core/animation/utils";
import { componentsConfig } from "config/components";

type AnimationConfig = Record<string, Record<string, AnimationProps>>;

export function withConfigure<
  Props extends {
    styles?: Record<string, string>;
    stylesReplace?: Record<string, string>;
    animationProps?: AnimationConfig;
  },
  Styles extends Record<string, string>,
>({
  componentName,
  Component,
  moduleStyles,
  moduleAnimationProps = {},
}: {
  componentName: string;
  Component: (props: Props) => JSX.Element | null;
  moduleStyles: Styles;
  moduleAnimationProps?: Record<string, Record<string, AnimationProps>>;
}) {
  let config = {
    styles: moduleStyles,
    animationProps: moduleAnimationProps,
  };

  const configure = (props: Partial<Props>) => {
    config = {
      ...props,
      styles: mergeStyles(
        moduleStyles,
        props?.styles as any as Styles,
        props?.stylesReplace as any as Styles,
        componentName,
      ),
      animationProps: mergeAnimationProps({
        animationPropsSource: moduleAnimationProps,
        animationPropsMerge: props?.animationProps,
      }),
    };
  };

  const componentConfig = (componentsConfig as any)?.[componentName];
  if (componentConfig) {
    configure(componentConfig);
  }

  const WithConfigure = (props: Props) => {
    const {
      styles: configStyles,
      animationProps: configAnimationProps,
      ...configProps
    } = config;

    const styles = mergeStyles(
      configStyles as any as Styles,
      props.styles as any as Styles,
      props.stylesReplace as any as Styles,
      componentName,
    );
    const animationProps = mergeAnimationProps({
      animationPropsSource: configAnimationProps,
      animationPropsMerge: props?.animationProps,
    });

    return (
      <Component
        {...configProps}
        {...props}
        styles={styles}
        animationProps={animationProps}
      />
    );
  };

  return WithConfigure;
}
