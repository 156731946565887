export const styles = {
  container: "flex gap-2",

  button_label:
    "hyam-text-small cursor-pointer select-none uppercase mb-0",
  button_label_dark: "text-hyam-white",

  button_label_selected:
    "underline font-medium",
};

export type HyamPrismicLangSwitchRowStyles =
  typeof styles;
