import _ from "lodash";
import cn from "classnames";
import { yTopFull } from "hyam-core/animation/defaults";

export const styles = {
  container: cn(
    "hyam-layout relative z-50",
    "fixed top-0 lg:pt-8",
    "flex",
    "items-center justify-between",
    "bg-hyam-white lg:pb-6",
    "transition-colors duration-1000",
  ),
  container_color_primary: cn("invert"),
  // "bg-hyam-primary-600 xl:bg-hyam-primary-600",
  container_scroll_top: "",

  logo_container: cn(
    // NOTE: setting the height of the whole nav bar on the logo container
    "h-hyam-navbar-h-sm xl:h-hyam-navbar-h-xl",
    "lg:fixed lg:left-0 lg:right-0 lg:mx-auto lg:w-fit",
    "flex items-center",
    "flex-none",
  ),
  logo: "lg:h-10 h-7 w-auto",

  links_container: cn(
    "antialiased font-body",
    "flex-none",
    "hidden xl:flex",
    "gap-12",
  ),

  buttons_container: cn(
    "hidden",
    "flex grow justify-end",
    "gap-8 md:gap-4 xl:gap-6",
    "items-center",
  ),

  hamburger_md: cn(
    "hidden md:block xl:hidden",
    "cursor-pointer",
  ),
  hamburger_sm: cn(
    "block md:hidden",
    "absolute right-4",
    "cursor-pointer",
  ),

  link: cn(
    "uppercase underline-offset-2 hover:underline !mb-0",
  ),

  link_active: "",

  button: "ml-2 xl:ml-0",
};

export type HyamPrismicDocumentNavBarStyle =
  typeof styles;

export const constants = {
  logoHeight: 24,
  hideDesktopNavBarThreshold: 50,
  scrollTopThreshold: -100,
  showThreshold: 70,
};

//
export const animationProps = {
  pageTransition: {
    container: {},
  },
  firstLoad: {
    container: _.merge(_.cloneDeep(yTopFull), {
      variants: {
        show: {
          transition: { delay: 1 },
        },
      },
    }),
  },
  onScroll: {
    container: yTopFull,
  },
};

export type HyamPrismicDocumentNavBarAnimationProps =
  typeof animationProps;
