import _ from "lodash";
import tailwindConfig from "root/tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";

// TODO Importing the whole tailwind theme will generate large bundle size.
//      Think of alternative, maybe create json file in a npm script
export const tailwindFullConfig = resolveConfig(tailwindConfig);

export const tailwindScreenSizesStrings = tailwindFullConfig?.theme?.screens as {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": "string";
};

export const tailwindScreenSizesNumbers = _.mapValues(
  tailwindScreenSizesStrings,
  (screen) => Number(_.isString(screen) ? screen.match(/[0-9]+/g) : 0),
);
