// import * as prismic from "@prismicio/client";
// import * as prismicNext from "@prismicio/next";
// import sm from "./sm.json";

const prismic = require("@prismicio/client");
const prismicNext = require("@prismicio/next");
const sm = require("./slicemachine.config.json");
const routes = require("./prismic-routes");

/**
 * The project's Prismic repository name.
 */
const repositoryName = sm.repositoryName;

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
const createClient = (config = {}) => {
  const client = prismic.createClient(sm.repositoryName, { routes, ...config });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};

module.exports = { repositoryName, createClient };
