import { ComponentType, FC, Suspense } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FormspreeProvider } from "@formspree/react";
import Link from "next/link";
import { AnimatePresence } from "framer-motion";
import {
  HyamPrismicDocumentFooterProps,
  HyamPrismicDocumentNavBar,
  HyamPrismicPreview,
} from "hyam-prismic/components";
import { AppProps } from "next/app";
import { PrismicProvider } from "@prismicio/react";
import { repositoryName } from "prismicio";
import globalData from "data/globalData.json";
import { NavBarDocument, FooterDocument } from "prismicio-types";
import { useRouter } from "next/router";
import { HyamGTM } from "hyam-core/components/HyamGTM";

import { useLayoutCssVariables } from "hyam-core/hooks/useLayoutCssVariables";
import { useOnScrollWindow } from "hyam-core/hooks/useOnScroll";
import { useOnRouteChange } from "hyam-core/hooks/useOnRouteChange";
import { HyamLayout, HyamGridGuide } from "hyam-core/components";
import {
  HyamPrismicLanguageSwitchModal,
  HyamPrismicSeoHead,
  HyamPrismicGlobalData,
} from "hyam-prismic/components";
import { HyamLocaleProvider } from "hyam-core/components";
import { useUsercentrics } from "hyam-core/hooks/useUsercentrics";

// type FooterSelect = "Footer Simple" | "Footer Full";

export type HyamPrismicNextJsAppProps = AppProps & {
  FooterComponent?: FC<HyamPrismicDocumentFooterProps>;
  AfterFooterComponent?: ComponentType;
};

let config: Partial<HyamPrismicNextJsAppProps> = {};
const configure = (props: Partial<HyamPrismicNextJsAppProps>) => {
  config = props;
};

export function HyamPrismicNextJsApp({
  Component,
  pageProps,
  AfterFooterComponent = config?.AfterFooterComponent,
  FooterComponent = config?.FooterComponent,
  router,
}: HyamPrismicNextJsAppProps) {
  // const footerSelect: FooterSelect = pageProps?.page?.data?.footer || "Footer Full";

  useLayoutCssVariables();
  useOnScrollWindow();
  useOnRouteChange();
  useUsercentrics();

  const { locale = "en" } = useRouter();
  const [shortLocale] = locale.split("-");

  const doc = pageProps?.page || pageProps?.document;

  const navBarDocument = (globalData.nav_bar as any)?.[shortLocale] as NavBarDocument;
  // const footerSimpleDocument = (globalData.footer_simple as any)?.[
  //   shortLocale
  // ] as FooterSimpleDocument;
  const footerDocument = (globalData.footer as any)?.[shortLocale] as FooterDocument;

  return (
    <PrismicProvider
      internalLinkComponent={(props) => {
        return <Link {...props} />;
      }}
      externalLinkComponent={(props) => {
        return (
          <a
            {...props}
            target={props?.target || "_blank"}
            rel={props?.rel || "noreferrer"}
          />
        );
      }}
    >
      <HyamGTM id={process.env.NEXT_PUBLIC_GTM_ID || ""} />
      <HyamPrismicPreview repositoryName={repositoryName}>
        <HyamLocaleProvider>
          <GoogleReCaptchaProvider
            scriptProps={{
              async: true,
              defer: true,
            }}
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""}
          >
            <FormspreeProvider project={process.env.NEXT_PUBLIC_FORMSPREE_PROJECT}>
              <HyamPrismicGlobalData.Provider
                value={{ page: { ...doc, data: { ...doc?.data, slices: null } } }}
              >
                <HyamGridGuide />
                <AnimatePresence mode="wait">
                  {/* Suspense workaround to fix "scroll before loading next page" bug */}
                  <Suspense fallback={<div />}>
                    <HyamLayout
                      key={router.asPath}
                      header={<HyamPrismicDocumentNavBar document={navBarDocument} />}
                      footer={
                        // footerSelect === "Footer Simple" ? (
                        //   <>
                        //     <HyamPrismicDocumentFooterSimple
                        //       document={footerSimpleDocument}
                        //     />
                        //     {AfterFooterComponent && <AfterFooterComponent />}
                        //   </>
                        // ) : (
                        <>
                          {FooterComponent && (
                            <FooterComponent
                              key={`footer-${locale}`}
                              document={footerDocument}
                            />
                          )}
                          {AfterFooterComponent && <AfterFooterComponent />}
                        </>
                      }
                    >
                      <HyamPrismicSeoHead
                        page={doc}
                        defaultLanguage={globalData.default_language}
                      />
                      <HyamPrismicLanguageSwitchModal page={doc} />
                      <Component {...pageProps} />
                    </HyamLayout>
                  </Suspense>
                </AnimatePresence>
              </HyamPrismicGlobalData.Provider>
            </FormspreeProvider>
          </GoogleReCaptchaProvider>
        </HyamLocaleProvider>
      </HyamPrismicPreview>
    </PrismicProvider>
  );
}

HyamPrismicNextJsApp.configure = configure;
