import cn from "classnames";

export const styles = {
  layout_container: cn(
    "hyam-layout-container",
    "h-full",
    "w-screen",
    "fixed top-0 left-0",
    "z-[60]",
    "bg-hyam-white",
  ),
  container: cn("hyam-layout", "flex-col"),

  top_container: cn(
    "flex",
    "items-center justify-between",
    "h-hyam-navbar-h-sm xl:h-hyam-navbar-h-xl",
    "flex-none",
    "border-b",
    "mb-8",
  ),

  close_icon: cn(
    "absolute",
    "right-2",
    "cursor-pointer",
    "p-2",
    "h-10 w-10",
  ),

  logo_container: "",
  logo: "mr-20 xl:h-10 h-7 w-auto",

  items_container: "",
  top_items_container: cn(
    "flex flex-col",
    "gap-y-8",
    "pb-8",
    // "border-b",
  ),
  middle_items_container: cn(
    "flex flex-col",
    "py-8",
    // "border-b",
  ),
  bottom_items_container: cn(
    "hidden  flex-col",
    "pt-8",
  ),

  link: cn(
    "text-hyam-black uppercase",
    "hover:underline underline-offset-2",
  ),

  button: "",
};

export type HyamPrismicDocumentNavBar_DrawerStyles =
  typeof styles;

export const constants = {
  logoHeight: 24,
};
