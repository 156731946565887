import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { useRouter } from "next/router";

import globalData from "data/globalData.json";

const locale_config = globalData.locale_config as any as Record<
  string,
  Record<string, string>
>;

export type HyamLocaleProviderProps = {
  children: ReactNode;
};

export const HyamLocaleProvider = ({ children }: HyamLocaleProviderProps) => {
  const { locale = "en" } = useRouter();
  const [shortLocale] = locale.split("-");
  const messages = locale_config[shortLocale];

  return (
    <IntlProvider locale={shortLocale} messages={messages}>
      {children}
    </IntlProvider>
  );
};
