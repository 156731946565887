import _ from "lodash";
import { AnimationProps } from "framer-motion";

type AnimationConfig = Record<string, Record<string, AnimationProps>>;

type MergeAnimationPropsProps = {
  animationPropsSource: AnimationConfig;
  animationPropsMerge?: AnimationConfig;
};

export const mergeAnimationProps = ({
  animationPropsSource,
  animationPropsMerge: animationPropsMerge,
}: MergeAnimationPropsProps) => {
  // TODO animationPropsReplace
  // return _.mapValues(animationPropsSource, (props, key) => {
  //   return animationPropsReplace?.[key] || props;
  // });

  return _.merge(animationPropsSource, animationPropsMerge);
};
