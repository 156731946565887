import cn from "classnames";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { useRouter } from "next/router";
import { withConfigure } from "hyam-core/utils/withConfigure";
import { styles as moduleStyles, HyamPrismicLangSwitchRowStyles } from "./styles";
import globalData from "data/globalData.json";
import { useSwitchLang } from "hyam-prismic/utils/locale";

const { all_languages } = globalData;

export type HyamPrismicLangSwitchRowProps = {
  styles?: Partial<HyamPrismicLangSwitchRowStyles>;
  stylesReplace?: Partial<HyamPrismicLangSwitchRowStyles>;
  variation?: "light" | "dark";
};

const _HyamPrismicLangSwitchRow = (props: HyamPrismicLangSwitchRowProps) => {
  const _styles = props.styles as HyamPrismicLangSwitchRowStyles;
  const { variation = "light" } = props;
  const { switchLang } = useSwitchLang();

  const router = useRouter();
  const locale = router.locale;

  const styles = _.mapValues(_styles, (style, styleKey) => {
    // default variation
    if (variation === "light") return style;

    const style_dark = (_styles as any)?.[styleKey + "_dark"];

    // apply variations conditionally
    return cn(style, {
      [style_dark]: variation === "dark",
    });
  });

  return (
    <div className={styles.container}>
      {_.map(all_languages, (lang, key) => {
        const langShort = lang.split("-")[0];
        const isCurrentLang = lang === locale;

        return (
          <div
            key={key}
            className={cn(styles.button_label, {
              [styles.button_label_selected]: isCurrentLang,
            })}
            onClick={() => switchLang(lang)}
          >
            <FormattedMessage id={"language_" + langShort} />
          </div>
        );
      })}
    </div>
  );
};

export const HyamPrismicLangSwitchRow = withConfigure<
  HyamPrismicLangSwitchRowProps,
  HyamPrismicLangSwitchRowStyles
>({
  componentName: "HyamPrismicLangSwitchRow",
  Component: _HyamPrismicLangSwitchRow,
  moduleStyles,
});
