import { useState, ComponentType } from "react";
import { motion } from "framer-motion";
import _ from "lodash";
import { PrismicNextImage } from "@prismicio/next";
import { NavBarDocument } from "prismicio-types";
import {
  constants,
  styles as moduleStyles,
  HyamPrismicDocumentNavBar_DrawerStyles,
} from "./styles";
import { useEventListener } from "hyam-core/utils/eventTarget";
import _CloseIcon from "hyam-core/svg/hamburger-close-24px.svg";
import {
  HyamPrismicLink,
  HyamPrismicLangSwitchRow,
  HyamPrismicButton,
} from "hyam-prismic/components";
import { lock, unlock } from "hyam-core/utils/bodyScrollLock";
import { withConfigure } from "hyam-core/utils/withConfigure";

export type HyamPrismicDocumentNavBar_DrawerProps = {
  document: NavBarDocument;
  styles?: Partial<HyamPrismicDocumentNavBar_DrawerStyles>;
  stylesReplace?: Partial<HyamPrismicDocumentNavBar_DrawerStyles>;
  CloseIcon?: ComponentType;
};

const _HyamPrismicDocumentNavBar_Drawer = (
  props: HyamPrismicDocumentNavBar_DrawerProps,
) => {
  const { CloseIcon = _CloseIcon } = props;
  const data = props.document.data;
  const styles = props.styles as HyamPrismicDocumentNavBar_DrawerStyles;

  const [show, setShow] = useState(false);
  const hasButtons = !_.isEmpty(data.buttons);

  const setShowTrue = () => {
    setShow(true);
    lock("HyamPrismicDocumentNavBar_Drawer");
  };

  const setShowFalse = () => {
    setShow(false);
    unlock("HyamPrismicDocumentNavBar_Drawer");
  };

  useEventListener(
    {
      name: "show_nav_bar_drawer",
      cb: setShowTrue,
    },
    [setShow],
  );
  useEventListener(
    {
      name: "hide_nav_bar_drawer",
      cb: setShowFalse,
    },
    [setShow],
  );

  return (
    <motion.div
      className={styles.layout_container}
      initial={{ x: "-100vw" }}
      animate={{ x: show ? "0vw" : "-100vw" }}
      transition={{
        ease: "easeInOut",
        duration: 0.4,
      }}
    >
      <div className={styles.container}>
        <div className={styles.top_container}>
          <HyamPrismicLink href="/" className={styles.logo_container}>
            <PrismicNextImage
              height={constants.logoHeight}
              className={styles.logo}
              field={data.logo}
            />
          </HyamPrismicLink>
          <CloseIcon className={styles.close_icon} onClick={setShowFalse} />
        </div>

        <div className={styles.items_container}>
          <div className={styles.top_items_container}>
            {_.map(data.nav_links, ({ link, link_label }, i) => {
              //TODO: Remove workaround
              const isConctactPage = /\w*contact\w*/i.test(link_label as string);

              return (
                <HyamPrismicLink
                  className={styles.link}
                  key={i}
                  field={link}
                  // TODO: Remove
                  href={isConctactPage ? "/contact" : undefined}
                >
                  {link_label}
                </HyamPrismicLink>
              );
            })}
          </div>
          {hasButtons && (
            <div className={styles.middle_items_container}>
              {_.map(data.buttons, ({ button_link, button_label }, i) => (
                <HyamPrismicButton
                  styles={{ container: styles.button }}
                  key={i}
                  field={button_link}
                >
                  {button_label}
                </HyamPrismicButton>
              ))}
            </div>
          )}
          <div className={styles.bottom_items_container}>
            <HyamPrismicLangSwitchRow />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const HyamPrismicDocumentNavBar_Drawer = withConfigure<
  HyamPrismicDocumentNavBar_DrawerProps,
  HyamPrismicDocumentNavBar_DrawerStyles
>({
  componentName: "HyamPrismicDocumentNavBar_Drawer",
  Component: _HyamPrismicDocumentNavBar_Drawer,
  moduleStyles,
});
