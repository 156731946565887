import { PrismicLink, PrismicLinkProps } from "@prismicio/react";
import { styles } from "./styles";
import { FilledLinkToWebField } from "@prismicio/client";
import globalData from "root/data/globalData.json";
import _ from "lodash";
import { classNamesWithMerge as cn } from "hyam-core/utils/tailwindMerge";

/**
 * We need a way to allow links to other languages, links to hash tags, etc.
 * Prismic doesn't currently provide this.
 *
 * The workaround is to use a Web link as an internal link and detect the
 * domain here to convert it into an internal link.
 */
const getInternalLinkHrefWorkaround = ({ url }: FilledLinkToWebField) => {
  // example: reimann-investors.com
  const domainUrl = process.env.NEXT_PUBLIC_DOMAIN_URL || "NOT_DEFINED";

  const domainUrlRegex = new RegExp(domainUrl + "(.*)");
  const vercelUrlRegex = /vercel.app(.*)/;

  const match = url.match(domainUrlRegex) || url.match(vercelUrlRegex);

  // (1) does not match
  if (match?.length !== 2) return;

  const urlObj = new URL(url);
  const pathname = urlObj.pathname + urlObj.hash;
  const pathDirs = pathname.split("/");

  // (2) match, non-default language
  if (_.includes(globalData.all_languages, pathDirs[1])) {
    return {
      href: pathname,
      locale: pathDirs[1],
    };
  }

  // (3) match, default language
  return {
    href: pathname,
    locale: globalData.default_language,
  };
};

export const HyamPrismicLink = (props: PrismicLinkProps) => {
  const propsAny = props as any;
  const fieldUrl = propsAny?.field?.url || propsAny.href || "";

  const phoneNumber = fieldUrl.match(/https...(\+[0-9]+)/)?.[1];
  const email = fieldUrl.match(/[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi)?.[0];

  const styles_container_link = cn(styles.container_link, props?.className);

  if (phoneNumber) {
    return (
      <a className={styles_container_link} href={`tel:${phoneNumber}`}>
        {props.children}
      </a>
    );
  }
  if (email) {
    return (
      <a className={styles_container_link} href={fieldUrl}>
        {props.children}
      </a>
    );
  }

  // by default PrismicLink renders null if no link is found. Instead, we're
  // rendering a non-link div.
  if (!fieldUrl) {
    return (
      <div onClick={propsAny?.onClick} className={styles_container_link}>
        {props.children}
      </div>
    );
  }

  if (props?.field?.link_type === "Web") {
    const webField = props.field as FilledLinkToWebField;

    const internalLinkHrefWorkaround = getInternalLinkHrefWorkaround(webField);

    if (internalLinkHrefWorkaround) {
      return (
        <PrismicLink<{ locale: string }>
          className={styles_container_link}
          href={internalLinkHrefWorkaround.href}
          locale={internalLinkHrefWorkaround.locale}
        >
          {props.children}
        </PrismicLink>
      );
    }

    // NOTE: target=_blank is not working with PrismicLink. Using an <a> instead.
    return (
      <a
        className={styles_container_link}
        href={webField.url}
        target={webField.target}
        rel={webField.target === "_blank" ? "noopener noreferrer" : undefined}
      >
        {props.children}
      </a>
    );
  }

  if (props?.href) {
    return (
      <PrismicLink className={styles_container_link} href={props?.href}>
        {props.children}
      </PrismicLink>
    );
  }

  return (
    <PrismicLink className={styles_container_link} document={props?.field as any}>
      {props.children}
    </PrismicLink>
  );
};
