import cn from "classnames";

export const styles = {
  container:
    "inline-block cursor-pointer antialiased",

  container_contained: cn(
    "group uppercase hyam-text-small w-fit",
    "underline decoration-transparent ",
    "hover:decoration-hyam-black hover:underline-offset-4 hover:decoration-2",
    "decoration-2 underline-offset-4 ",
    "flex items-center gap-2 hyam-animate-text",
  ),
  container_contained_white: cn(
    "!text-hyam-white !uppercase",
    "hover:!decoration-hyam-white !hyam-animate-text-white",
  ),
  container_outline: cn(
    "px-12",
    "py-3",
    "rounded-lg",
    "border",
    "border-hyam-primary-600",
    "hover:border-hyam-primary-800",
    "bg-hyam-white",
    "text-hyam-primary-600",
    "hover:text-hyam-primary-800",
  ),
  container_text: cn(
    "items-center",
    "justify-center",
    "bg-hyam-white",
    "text-hyam-primary-600",
    "hover:text-hyam-primary-800",
  ),

  right_icon_container:
    " group-hover:translate-x-2 transition duration-200 ease-out",
  left_icon_container: "",
  prismic_link_wrapper: "",
  container_contained_secondary: cn(
    "uppercase hyam-text-small py-2 px-4  w-full sm:w-fit duration-300",
    " text-white border-white border hover:bg-hyam-white hover:text-hyam-black transition ",
  ),

  container_contained_secondary_black: cn(
    "uppercase hyam-text-small py-2 px-4  w-full sm:w-fit duration-300",
    "border text-hyam-black border-hyam-black hover:bg-hyam-black hover:text-white transition ",
  ),
};

export type HyamPrismicButtonStyles =
  typeof styles;
