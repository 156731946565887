const plugin = require("tailwindcss/plugin");
//const colors = require("tailwindcss/colors");
//const defaultTheme = require("tailwindcss/defaultTheme");

/** @type {import('tailwindcss').Config} */

module.exports = {
  content: [
    "./packages/**/*.{js,ts,jsx,tsx,css}",
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./slices/**/*.{js,ts,jsx,tsx}",
    "./config/**/*.{js,ts,jsx,tsx}",
    "./src/components/**/*.{js,ts,jsx,tsx}",
    "./stories/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    fontFamily: {
      // NOTE: When adding custom font, make sure bold, italics and link
      // (underline) work for all typography styles (h1-h6, body, etc.)
      //
      // display: ["Internacional", "sans-serif"],
      // body: ["Internacional", "sans-serif"],
      display: ["PPFormulaCondensed", "display"],
      body: ["TWKLausanne", "sans-serif"],
    },
    fontSize: {
      // [font size, line height]
      "5xl": ["8.06rem", "8rem"],
      "4xl": ["6.18rem", "6.25rem"],
      "3xl": ["4.68rem", "4.75rem"],
      "2xl": ["2.75rem", "3.5rem"],
      xl: ["2.12rem", "3rem"],
      large: ["1.62rem", "2.5rem"],
      medium: ["1.25rem", "2rem"],
      small: ["0.94rem", "1.5rem"],
      caption: ["0.75rem", "1rem"],

      "m-5xl": ["3.5rem", "3.625rem"],
      "m-4xl": ["2.5rem", "2.75rem"],
      "m-3xl": ["2rem", "2.375rem"],
      "m-2xl": ["1.625rem", "2rem"],
      "m-xl": ["1.375rem", "1.875rem"],
      "m-large": ["1.375rem", "1.875rem"],
      "m-medium": ["1.25rem", "1.875rem"],
      "m-small": ["0.94rem", "1.5rem"],
    },
    extend: {
      colors: {
        "hyam-black": "#111",
        "hyam-white": "#fff",
        "hyam-grey": "#A8A8A8",
        "hyam-dark-grey": "#4E4E4E",
        "hyam-alert-red": "#EF4444",
        "hyam-check-green": "#10B981",
        "hyam-primary": {
          600: "#777",
        },
      },
      spacing: {
        19: "76px",
        86: "344px",
        "hyam-navbar-h-sm": "64px",
        "hyam-navbar-h-lg": "72px",
        "hyam-navbar-h-xl": "72px",
      },
      // target classes at one breakpoint only and ignore the rest
      // https://morrislaptop.medium.com/a-default-first-approach-with-tailwind-532a249a0b01
      screens: {
        "md-only": { min: "678px", max: "1024px" },
        "md-less": { min: "0px", max: "678px" },
        "xl-less": { min: "0px", max: "1280px" },
        "landscape-mobile": {
          raw: "(orientation: landscape) and (max-height: 600px)",
        },
      },
      aspectRatio: {
        "hyam-169": "16 / 9",
        "hyam-34": "3 / 4",
        "hyam-45": "4 / 5",
        "hyam-43": "4 / 3",
        "hyam-11": "1 / 1",
      },
      dropShadow: {
        xl: "0 20px 20px rgba(0, 0, 0, 0.04)",
      },
      keyframes: {
        slide: {
          "100%": {
            transform: "translateX(-50%)",
          },
        },
      },
      animation: {
        marquee: "slide 60s linear infinite",
      },
      content: {
        "link-arrow-right-white": 'url("/link-arrow-right-white.svg")',
      },
    },
  },
  plugins: [
    require("tailwind-scrollbar-hide"),
    require("@tailwindcss/forms"),
    plugin(function ({ addVariant }) {
      addVariant("children", "& > *");
      addVariant("slider-thumb", ["&::-webkit-slider-thumb", "&::slider-thumb"]);
      addVariant("not-fist-child", "&:not(:first-child)");
    }),
  ],
};
