import { useState, useEffect } from "react";
import { useWindowSize } from "usehooks-ts";
import { tailwindScreenSizesNumbers as screens } from "hyam-core/utils/getTailwindTheme";

export type BreakpointName = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "ssg";
export type BreakpointIndex = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export const useBreakpoint: () => BreakpointName = () => {
  const [breakpoint, setBreakpoint] = useState("ssg" as BreakpointName);
  const { width } = useWindowSize();

  const getNewBreakpoint = (_width: number) => {
    if (_width < screens.sm) return "xs";
    if (_width < screens.md) return "sm";
    if (_width < screens.lg) return "md";
    if (_width < screens.xl) return "lg";
    if (_width < screens["2xl"]) return "xl";

    return "2xl";
  };

  useEffect(() => {
    const newBreakpoint = getNewBreakpoint(width);
    if (newBreakpoint !== breakpoint) {
      setBreakpoint(newBreakpoint);
    }
  }, [width, breakpoint]);

  return breakpoint;
};

export const useBreakpointIndex: () => BreakpointIndex = () => {
  const breakpoint = useBreakpoint();

  const INDEX_MAP = {
    ssg: -1,
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    "2xl": 5,
  };

  return INDEX_MAP[breakpoint] as BreakpointIndex;
};
